import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

/**
 * This class is responsible for reusing routes.
 *
 * We can use this interface to prevent destroying and re-creating components.
 *
 * We are using this for the purpose of not re-creating the cases index component so that we
 * can preserve the scroll position on the page as users navigate between cases.
 *
 * To re-usu routes for your component, all you need to do is add
 *
 * data: {
 *   routeReuseKey: '<unique key here>'
 * }
 *
 * to the route config.
 */
export class AppRouteReuseStrategy implements RouteReuseStrategy {

  private routeStore = new Map<string, DetachedRouteHandle>();

  /** Determines if this route (and its subtree) should be detached (i.e. stored) to be reused later */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.routeReuseKey
  }

  /** Stores the detached route.*/
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (!route.data.routeReuseKey) {
      return
    }

    this.routeStore.set(route.data.routeReuseKey, handle);
  }

  /** Determines if this route (and its subtree) should be reattached (i.e. restored from the state saved in routeStore) */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data?.routeReuseKey && this.routeStore.has(route.data.routeReuseKey)
  }

  /** Retrieves the previously stored route */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.data?.routeReuseKey) {
      return null
    }

    return this.routeStore.get(route.data.routeReuseKey);
  }

  /** Determines if a route should be reused */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig
  }
}