import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

@Injectable({providedIn: 'root'})
export class PendingRequestsStore {
  private _numPendingRequests: number = 0
  private _recentMax: number = 0

  private _hasPendingRequests = new BehaviorSubject<boolean>(false)
  hasPendingRequests = this._hasPendingRequests.asObservable()

  private _progress = new BehaviorSubject<number>(null)
  progress = this._progress.asObservable()

  public get numPendingRequests(): number {
    return this._numPendingRequests
  }

  // 2 - 100
  public get progressPercentage(): number {
    if (!this._recentMax) {
      return null
    }
    return Math.max(2, ((this._recentMax - this._numPendingRequests) / this._recentMax) * 100.0)
  }

  public increment() {
    this.set(this._numPendingRequests + 1)
  }

  public decrement() {
    this.set(this._numPendingRequests - 1)
  }

  public set(val: number) {
    this._numPendingRequests = val
    if (val > this._recentMax) {
      this._recentMax = val
    } else if (!val) {
      this._resetRecentMax()
    }
    this._hasPendingRequests.next(!!this._numPendingRequests)
    this._progress.next(this.progressPercentage)
  }

  // persist 100% progress for 500ms before progress gets reset back to 0
  private _resetRecentMax() {
    setTimeout(() => {
      this._recentMax = 0
      this._progress.next(null)
    }, 500)
  }
}
