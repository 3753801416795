import {Component} from '@angular/core'
import {CommonModule} from '@angular/common'

@Component({
  selector: 'lib-required-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './required-indicator.component.html',
})
export class RequiredIndicatorComponent {}
