import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/lib/environments/environment'
import {InvitationStatuses, ICaseInvitation} from '@/lib/app/models/case-invitation.interface'
import {lastValueFrom} from 'rxjs'
import {map} from 'rxjs/operators'
import {ICase} from '@/lib/app/models/case.interface'

@Injectable({
  providedIn: 'root',
})
export class CaseInvitationService {
  constructor(private _http: HttpClient) {}

  update(caseId: ICase['id'], id: ICaseInvitation['id'], data: Partial<ICaseInvitation>): Promise<ICaseInvitation> {
    const obs = this._http
      .put<{data: ICaseInvitation}>(`${environment.api}/v3/business/cases/${caseId}/invitations/${id}`, data)
      .pipe(map(res => res.data))
    return lastValueFrom(obs)
  }

  send(caseId: ICase['id'], id: ICaseInvitation['id']): Promise<ICaseInvitation> {
    const obs = this._http
      .post<{data: ICaseInvitation}>(`${environment.api}/v3/business/cases/${caseId}/invitations/${id}/send`, null)
      .pipe(map(res => res.data))
    return lastValueFrom(obs)
  }

  resendInitialInvitation(caseId: ICase['id'], id: ICaseInvitation['id']): Promise<ICaseInvitation> {
    const obs = this._http
      .post<{data: ICaseInvitation}>(`${environment.api}/v3/business/cases/${caseId}/invitations/${id}/resend`, null)
      .pipe(map(res => res.data))
    return lastValueFrom(obs)
  }

  isStatusBounced(c: ICase): boolean {
    if (c.owner?.case_invitation) {
      return c.owner.case_invitation.status === InvitationStatuses.BOUNCED
    }
  }

  isStatusDraft(c: ICase): boolean {
    if (c.owner?.case_invitation) {
      return c.owner.case_invitation.status === InvitationStatuses.DRAFT
    }
  }
}
