import {ChangeDetectorRef, Component, OnInit, ViewContainerRef} from '@angular/core'
import {PendingRequestsStore} from '@/lib/app/stores/pending-requests.store'
import {AnalyticsService} from '@/lib/app/services/analytics.service'
import {environment} from '@/org/environments/environment'
import {NavigationError, Router} from '@angular/router'
import {AlertService} from '@/lib/app/services/alert.service'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'
import {get} from 'lodash'
import {AuthService} from '@/lib/app/services/auth.service'

@Component({
  selector: 'org-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  hasPendingRequests: boolean

  constructor(
    private _store: PendingRequestsStore,
    private _cdr: ChangeDetectorRef,
    private _analytics: AnalyticsService,
    private _router: Router,
    private _alerts: AlertService,
    private _auth: AuthService,
    public viewContainerRef: ViewContainerRef, // used by modal.service.ts
    private _activeOrg: ActiveOrgStore
  ) {}

  ngOnInit(): void {
    this._store.hasPendingRequests.subscribe(val => {
      if (this.hasPendingRequests != val) {
        this.hasPendingRequests = val
        this._cdr.detectChanges()
      }
    })

    this._analytics
      .initializeAnalytics(environment.ga4_measurement_id, () => {
        // add this data to every Google Analytics event
        return {
          is_user_authenticated: !!this._auth.token,
          is_test: get(this._activeOrg, 'org.is_test'),
        }
      })
      .then(r => console.log('@org/app/app.component', 'Analytics initialized', r))
      .catch(e => console.error('@org/app/app.component', 'Unable to initialize Analytics.', e))

    this._router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        //ChunkLoadError can happen if we deploy while user is using the app, and they
        // try to route to an area of the app that has lazy loaded components not accessed
        // yet during their session.
        const chunkFailedMessage = /Loading chunk [\d]+ failed/
        if (chunkFailedMessage.test(event.error?.message)) {
          this._alerts.info(
            `
            <div class="mb-2">We have made a few updates since you opened the page.</div>
            <div>To continue using Cadence, please</div>
            <div>
              <a href="${window.location}" class="text-dark">refresh the page</a>.
            </div>`,
            {auto_close: 0}
          )
        }
      }
    })
  }
}
