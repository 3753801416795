import {Injectable} from '@angular/core'
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor(private _http: HttpClient) {}

  async download(url, params?: any) {
    await this.downloadResponse(this._http.get(url, {observe: 'response', responseType: 'blob', params}))
  }

  async downloadResponse(response: Observable<HttpResponse<any>>) {
    try {
      const res = await response.toPromise()
      let filename = FileDownloaderService.getFilenameFromContentDisposition(res, 'file')
      FileDownloaderService.downloadBlob(res.body, filename)
    } catch (e) {
      if (e.error instanceof HttpErrorResponse && e.error.error instanceof Blob) {
        // convert blob response back to json on error so that we can get the error message out
        e.error.error = JSON.parse(await e.error.error.text())
      }
      throw e
    }
  }

  async view(url) {
    const res = await this._http.get(url, {observe: 'response', responseType: 'blob'}).toPromise()
    FileDownloaderService.downloadBlob(res.body)
  }

  public static downloadBlob(blob: Blob, downloadAs: string = null) {
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('style', 'display: none')
    a.href = url
    if (downloadAs) {
      a.download = downloadAs
    } else {
      a.target = '_blank'
    }
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  }

  private static getFilenameFromContentDisposition(
    response: HttpResponse<any>,
    fallbackFilename: string = 'file'
  ): string {
    const disposition = response.headers.get('Content-Disposition')
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '')
      }
    }

    return fallbackFilename
  }
}
