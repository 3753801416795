/**
 * Formats a phone number in the format of 3334445555 to (333) 444-5555
 * @param phone
 */
export function formatPhone(phone: string): string {
  if (!phone) {
    return ''
  }

  phone = phone.replace(/\D+/g, '')
  if (phone.startsWith('1')) {
    phone = phone.replace('1', '')
  }

  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
}
