import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {AlertService} from '@/lib/app/services/alert.service'
import {TempFileUploadService} from '@/lib/app/services/temp-file-upload.service'
import {ITempUploadedFile} from '@/lib/app/models/temp-uploaded-file.interface'
import {extendPromiseWithQueryableState, IPromiseWithState} from '@/lib/app/domain/PromiseWithState'

@Component({
  selector: 'lib-temp-file-uploader',
  templateUrl: './temp-file-uploader.component.html',
})
export class TempFileUploaderComponent implements OnInit {
  @Input() hidden: boolean
  @Input() accept: string[] = ['application/pdf']
  @Output('fileUploaded') fileUploadedEmitter = new EventEmitter<ITempUploadedFile>()
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>

  uploadPromise: IPromiseWithState

  constructor(private _alerts: AlertService, private _tempFiles: TempFileUploadService) {}

  ngOnInit(): void {}

  async onFileSelect(e: Event) {
    const target = e.target as HTMLInputElement
    const files = target.files as FileList
    const file = files[0]
    let fileData
    try {
      this.uploadPromise = extendPromiseWithQueryableState(this._tempFiles.upload(file))
      fileData = await this.uploadPromise
    } catch (e) {
      this._alerts.error(e)
      return
    }
    this.fileUploadedEmitter.emit(fileData)
  }

  reset() {
    this.uploadPromise = null
    this.fileInput.nativeElement.value = ''
  }
}
