export const VALUE_OPTION_TYPE_PRIMARY_RELATION = 3
export const VALUE_OPTION_TYPE_SECONDARY_RELATION = 4
export const VALUE_OPTION_VALUE_ADMIN_OF_ESTATE = 'administrator-of-the-estate'
export const VALUE_OPTION_VALUE_EXEC_OF_ESTATE = 'executor-of-the-estate'

export interface IValueOption {
  type: number
  value: string
  label: string
}

export const VALUE_OPTION_TYPE_SECONDARY_RELATION_LABELS_BY_VALUE = {
  spouse: 'Spouse',
  child: 'Child',
  grandchild: 'Grandchild',
  parent: 'Parent',
  sibling: 'Sibling',
  niece: 'Niece',
  nephew: 'Nephew',
  grandmother: 'Grandmother',
  grandfather: 'Grandfather',
  aunt: 'Aunt',
  uncle: 'Uncle',
  cousin: 'Cousin',
  friend: 'Friend',
  neighbor: 'Neighbor',
  acquaintance: 'Acquaintance',
  none: 'N/A',
  brother: 'Brother',
  sister: 'Sister',
  grandson: 'Grandson',
  granddaughter: 'Granddaughter',
}
