import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {InvalidFeedbackComponent} from './invalid-feedback.component'
import {RouterModule} from '@angular/router'

@NgModule({
  declarations: [InvalidFeedbackComponent],
  exports: [InvalidFeedbackComponent],
  imports: [CommonModule, RouterModule],
})
export class InvalidFeedbackModule {}
