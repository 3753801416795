import {environment as lib} from '@/lib/environments/environment'

export const environment = {
  production: true,
  api: lib.api,
  ga4_measurement_id: 'G-E2ZPVVWM5T',
  segment_write_key: 'Q4rBHiC3HltrlU3Rv9uYE2xcF9iectiJ',
  endUserHostNames: lib.endUserHostNames,
  stripeKey: lib.stripeKey,
}
