import {Directive, ElementRef} from '@angular/core'

@Directive({
  selector: '[libToggleUserPasswordVisibility]',
})
export class ToggleUserPasswordVisibilityDirective {
  private _shown: boolean

  constructor(private el: ElementRef) {
    const parent = this.el.nativeElement.parentNode

    const button = document.createElement('button')
    const icon = document.createElement('i')
    button.type = 'button'
    button.className = 'btn btn-light rounded-end'
    icon.className = 'fa-solid fa-eye'
    button.tabIndex = -1

    button.addEventListener('click', e => {
      this.toggle(icon)
      e.preventDefault()
    })

    button.appendChild(icon)
    parent.appendChild(button)
  }

  toggle(icon: HTMLElement) {
    this._shown = !this._shown
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text')
      icon.className = 'fa-solid fa-eye-slash'
    } else {
      this.el.nativeElement.setAttribute('type', 'password')
      icon.className = 'fa-solid fa-eye'
    }
  }
}
