import {Injectable} from '@angular/core'
import {BaseCollectionStore} from '@/lib/app/stores/base-collection.store'
import {AuthDestroyerService} from '@/lib/app/services/auth-destroyer.service'
import {IOrg} from '@/lib/app/models/org.interface'
import {ILocation} from '@/org/app/models/location.interface'
import {LocationService} from '@/org/app/services/location.service'

@Injectable({providedIn: 'root'})
export class LocationStore extends BaseCollectionStore<ILocation> {
  constructor(destroyer: AuthDestroyerService, private _locationService: LocationService) {
    super(destroyer)
  }

  async fetchAll(orgId: IOrg['id']) {
    this.setItems(await this._locationService.fetchAll(orgId))
  }
}
