import {ElementRef, Injectable} from '@angular/core'
import {trim} from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor() {}

  async confirm(message: string | ElementRef = 'Are you sure?'): Promise<boolean> {
    const modalEl = document.getElementById('confirmation-modal')
    const modal = new window['bootstrap'].Modal(modalEl)
    const confirmBtn = document.getElementById('confirmation-modal-confirm')
    const denyBtn = document.getElementById('confirmation-modal-deny')
    const textEl = document.getElementById('confirmation-modal-text')

    textEl.innerHTML = typeof message === 'string' ? message : message.nativeElement.innerHTML
    modalEl.classList.add('is-pii')
    modalEl.addEventListener('shown.bs.modal', () => confirmBtn.focus())
    modal.show()

    let resolve
    const promise = new Promise<boolean>(r => {
      resolve = r
    })
    confirmBtn.addEventListener('click', () => {
      resolve(true)
      modal.hide()
    })
    denyBtn.addEventListener('click', () => resolve(false))
    modalEl.addEventListener('hide.bs.modal', () => resolve(false))

    return promise
  }

  async passwordConfirm(message?: string | ElementRef): Promise<string> {
    const modalEl = document.getElementById('password-confirmation-modal')
    const modal = new window['bootstrap'].Modal(modalEl)
    const confirmBtn = document.getElementById('password-confirmation-modal-confirm')
    const denyBtn = document.getElementById('password-confirmation-modal-deny')
    const textEl = document.getElementById('password-confirmation-modal-text')
    const inputEl = document.getElementById('password-confirmation-input') as HTMLInputElement
    inputEl.value = ''

    textEl.innerHTML =
      typeof message === 'string' ? message : message?.nativeElement?.innerHTML || 'Confirm your password to proceed'
    modalEl.addEventListener('shown.bs.modal', () => confirmBtn.focus())
    modal.show()

    let resolve
    const promise = new Promise<string>(r => {
      resolve = r
    })
    confirmBtn.addEventListener('click', () => {
      resolve(inputEl.value)
      modal.hide()
    })
    denyBtn.addEventListener('click', () => resolve(null))
    modalEl.addEventListener('hide.bs.modal', () => resolve(null))

    return promise
  }

  async stringConfirm(confirmationString: string, message?: string | ElementRef): Promise<string> {
    const modalEl = document.getElementById('string-confirmation-modal')
    const modal = new window['bootstrap'].Modal(modalEl)
    const confirmBtn = document.getElementById('string-confirmation-modal-confirm') as HTMLButtonElement
    const denyBtn = document.getElementById('string-confirmation-modal-deny')
    const textEl = document.getElementById('string-confirmation-modal-text')
    const confirmationEl = document.getElementById('string-confirmation-modal-confirmation-string')
    const inputEl = document.getElementById('string-confirmation-input') as HTMLInputElement
    inputEl.value = ''
    inputEl.placeholder = confirmationString

    textEl.innerHTML =
      typeof message === 'string' ? message : message?.nativeElement?.innerHTML || 'Type confirm to proceed'
    confirmationEl.innerHTML = `"${confirmationString}"`
    modalEl.addEventListener('shown.bs.modal', () => inputEl.focus())
    confirmBtn.disabled = true
    modal.show()

    let resolve
    const promise = new Promise<string>(r => {
      resolve = r
    })

    inputEl.addEventListener('input', () => {
      confirmBtn.disabled = trim(inputEl.value) !== confirmationString
    })

    confirmBtn.addEventListener('click', () => {
      resolve(inputEl.value)
      modal.hide()
    })
    denyBtn.addEventListener('click', () => resolve(null))
    modalEl.addEventListener('hide.bs.modal', () => resolve(null))

    return promise
  }
}
