import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'
import {ActivatedRoute} from '@angular/router'
import {defer} from 'lodash'
import {ThemeSwitcherService} from '@/lib/app/services/theme-switcher.service'

@Component({
  selector: 'lib-style-guide',
  templateUrl: './style-guide.component.html',
})
export class StyleGuideComponent implements OnInit {
  @ViewChild('bdLayout') bdLayout: ElementRef<HTMLDivElement>
  public docsHTML: SafeHtml

  constructor(
    private _http: HttpClient,
    private _sanitized: DomSanitizer,
    private _activeRoute: ActivatedRoute,
    private _themeSwitcher: ThemeSwitcherService
  ) {}

  async ngOnInit() {
    const theme = this._activeRoute.snapshot.queryParams['theme']
    this._themeSwitcher.setTheme(theme)

    const path = this._activeRoute.snapshot.queryParams['path'] ?? '/docs/5.3/content/typography/'
    const scratchPad = document.createElement('div')
    scratchPad.innerHTML = await this._http
      .request('GET', `https://getbootstrap.com/${path}`, {responseType: 'text'})
      .toPromise()

    this.docsHTML = this._sanitized.bypassSecurityTrustHtml(scratchPad.querySelectorAll('.bd-layout')[0].innerHTML)

    defer(() =>
      this.bdLayout.nativeElement.querySelectorAll('a[href]').forEach((a: HTMLAnchorElement) => {
        const path = a.href.toString().replace(`${window.location.origin}/`, '')
        a.href = path.startsWith('#')
          ? `${window.location.toString()}${path}`
          : `/styles?path=/${path}&theme=${theme || ''}`
      })
    )
  }
}
