import {Params} from '@angular/router'

export class Sort {
  private _field: string
  private _dir: 'asc' | 'desc'

  get field() {
    return this._field
  }

  get dir() {
    return this._dir
  }

  public reset() {
    this._field = null
  }

  public set(field: string, dir: 'asc' | 'desc') {
    this._field = field
    this._dir = dir
  }

  public query() {
    if (!this._field) {
      return null
    }
    if (this._dir === 'desc') {
      return {
        sort: this._field,
        order: 'desc',
      }
    } else {
      return {
        sort: this._field,
      }
    }
  }

  setFromQuery(queryParams: Params) {
    this._field = queryParams['sort']
    this._dir = queryParams['order'] ?? 'asc'
  }

  hasSort() {
    return !!this._field
  }
}
