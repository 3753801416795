import {Injectable} from '@angular/core'
import {HttpClient, HttpContext} from '@angular/common/http'
import {ICase} from '@/lib/app/models/case.interface'
import {IPaginated} from '@/lib/app/models/paginated.interface'
import {environment} from '@/lib/environments/environment'
import {map} from 'rxjs/operators'
import {lastValueFrom} from 'rxjs'
import {
  IValueOption,
  VALUE_OPTION_TYPE_PRIMARY_RELATION,
  VALUE_OPTION_TYPE_SECONDARY_RELATION,
  VALUE_OPTION_TYPE_SECONDARY_RELATION_LABELS_BY_VALUE,
} from '@/lib/app/models/value-option.interface'
import {each} from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(private _http: HttpClient) {}

  fetchAll(params: any = {}, context: HttpContext = null): Promise<ICase[]> {
    return this._http
      .get<{data}>(`${environment.api}/v3/business/cases`, {params, context})
      .pipe(map(({data}) => data))
      .toPromise()
  }

  fetchPaginated(params: any): Promise<IPaginated<ICase[]>> {
    return this._http
      .get(`${environment.api}/v3/business/cases`, {params})
      .pipe(map(res => ({data: res['data'], meta: res['meta']})))
      .toPromise()
  }

  fetchById(id: ICase['id']): Promise<ICase> {
    return this._http
      .get<{data}>(`${environment.api}/v3/business/cases/${id}`)
      .pipe(map(({data}) => data))
      .toPromise()
  }

  async fetchPrimaryRelationOptions(): Promise<IValueOption[]> {
    const response = (await this._http
      .get(`${environment.api}/v3/business/value-options`, {params: {type: VALUE_OPTION_TYPE_PRIMARY_RELATION}})
      .toPromise()) as unknown as {data: IValueOption[]}

    return response.data
  }

  async fetchSecondaryRelationOptions(): Promise<IValueOption[]> {
    const response = (await this._http
      .get(`${environment.api}/v3/business/value-options`, {params: {type: VALUE_OPTION_TYPE_SECONDARY_RELATION}})
      .toPromise()) as unknown as {data: IValueOption[]}

    const labelsByValue = VALUE_OPTION_TYPE_SECONDARY_RELATION_LABELS_BY_VALUE
    each(response.data, option => (option.label = labelsByValue[option.value]))

    return response.data
  }

  create(data: FormData): Promise<ICase> {
    return this._http
      .post(`${environment.api}/v3/business/cases`, data)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  update(id: ICase['id'], data: Partial<ICase>): Promise<ICase> {
    const obs = this._http.put(`${environment.api}/v3/business/cases/${id}`, data).pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }

  delete(id: ICase['id']): Promise<any> {
    const obs = this._http.delete(`${environment.api}/v3/business/cases/${id}`)
    return lastValueFrom(obs)
  }
}
