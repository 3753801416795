export const NUM_MONTHS_UNTIL_EXPIRES = 6

export function isPreNeedContactAtExpired(pre_need_lead_created_at, pre_need_lead_contact_at): boolean {
  const preNeedCreatedAt = new Date(pre_need_lead_created_at)
  const expiration = new Date(
    preNeedCreatedAt.getFullYear(),
    preNeedCreatedAt.getMonth() + NUM_MONTHS_UNTIL_EXPIRES,
    preNeedCreatedAt.getDate()
  )

  return new Date(pre_need_lead_contact_at) > expiration
}
