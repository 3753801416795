<ng-template #modal>
  <div id="onboarding-modal" class="modal fade" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog mt-2 modal-lg">
      <div class="modal-content shadow-lg text-center">
        <div id="businessAppOnboardingModal" class="carousel slide">
          <div class="carousel-inner">
            <div
              *ngFor="let slide of onboarding_elements; let firstItem = first"
              class="carousel-item"
              [ngClass]="{active: firstItem}">
              <div style="height: 100px" class="justify-content-center align-items-center py-1 py-lg-4">
                <h1 class="px-1 px-lg-5">
                  {{ slide.title }}
                </h1>
              </div>
              <div style="height: 425px" class="py-1 py-lg-4 px-1 px-lg-5">
                <p class="px-1">
                  {{ slide.description }}
                </p>
                <img
                  *ngIf="slide.slideType === 'slide'"
                  src="{{ slide.imageURL }}"
                  alt="{{ slide.title }}"
                  class="img-fluid mx-auto py-2" />
                <iframe
                  *ngIf="slide.slideType === 'video'"
                  width="100%"
                  height="85%"
                  [src]="slide.videoURL"
                  title="{{ slide.title }}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <hr />
          <div class="row align-content-center align-items-center py-4">
            <div class="col col-4 justify-content-center">
              <a
                class="btn text-muted px-3"
                type="button"
                data-bs-target="#businessAppOnboardingModal"
                data-bs-slide="prev"
                (click)="prevSlide()"
                *ngIf="currentSlideIndex > 0">
                Back
              </a>
            </div>
            <div class="col col-4 justify-content-center">
              <a
                class="btn btn-primary px-3"
                type="button"
                data-bs-target="#businessAppOnboardingModal"
                data-bs-slide="next"
                (click)="nextSlide()"
                *ngIf="currentSlideIndex < onboarding_elements.length - 1">
                Next
              </a>
              <a
                class="btn btn-primary px-3"
                type="button"
                (click)="onExit()"
                *ngIf="currentSlideIndex === onboarding_elements.length - 1">
                Finish
              </a>
            </div>
            <div class="col col-4 justify-content-center">
              <button class="btn text-muted px-3" (click)="onExit()">Skip</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
