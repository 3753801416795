import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/org/environments/environment'
import {map} from 'rxjs/operators'
import {IOrg} from '@/lib/app/models/org.interface'
import {ILocation} from '../models/location.interface'

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient) {}

  fetchAll(orgId: IOrg['id']): Promise<ILocation[]> {
    return this.http
      .get(`${environment.api}/v3/business/orgs/${orgId}/locations`)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  fetchById(orgId: IOrg['id'], id: ILocation['id']): Promise<ILocation> {
    return this.http
      .get(`${environment.api}/v3/business/orgs/${orgId}/locations/${id}`)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  post(orgId: IOrg['id'], data: Partial<ILocation>): Promise<ILocation> {
    return this.http
      .post(`${environment.api}/v3/business/orgs/${orgId}/locations`, data)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  put(orgId: IOrg['id'], id: ILocation['id'], data: Partial<ILocation>): Promise<ILocation> {
    return this.http
      .put(`${environment.api}/v3/business/orgs/${orgId}/locations/${id}`, data)
      .pipe(map(res => res['data']))
      .toPromise()
  }
}
