import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {assign, kebabCase} from 'lodash'
import {DOCUMENT} from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class ScriptFetcherService {
  private renderer: Renderer2

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  async fetch(src: string): Promise<boolean> {
    const sluggedSrc = kebabCase(src)
    const doesScriptExist = !!this._document.getElementById(sluggedSrc)

    if (doesScriptExist) {
      return Promise.resolve(false)
    }

    return new Promise((resolve, reject) => {
      const props = {
        id: sluggedSrc,
        type: 'text/javascript',
        src,
        async: true,
        // defer: true,
        onload: () => resolve(true),
        onerror: reject,
      }

      this.renderer.appendChild(this._document.body, assign(this.renderer.createElement('script'), props))
    })
  }
}
