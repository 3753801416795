export enum InvitationStatuses {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DRAFT = 'draft',
  BOUNCED = 'bounced',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
}

export interface ICaseInvitation {
  id: number
  token: string
  email: string
  phone: string
  send_sms: boolean
  first_name: string
  last_name: string
  status: InvitationStatuses
  link: string
}
