import {Injectable} from '@angular/core'
import {AuthService} from '@/lib/app/services/auth.service'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private _auth: AuthService) {}

  getUserState(app: 'admin' | 'org' | 'enduser', key: string, defaultVal: any = null): any {
    return localStorage.getItem(`${app}/${this._auth.user.id}:${key}`) || defaultVal
  }

  setUserState(app: 'admin' | 'org' | 'enduser', key: string, data: any) {
    localStorage.setItem(`${app}/${this._auth.user.id}:${key}`, data)
  }

  removeUserState(app: 'admin' | 'org' | 'enduser', key: string) {
    localStorage.removeItem(`${app}/${this._auth.user.id}:${key}`)
  }
}
