import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'

@Component({
  selector: 'lib-is-test-indicator',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './is-test-indicator.component.html',
})
export class IsTestIndicatorComponent {
  @Input() info: string | null
  @Input() variant: 'full' | 'compact' = 'full'
}
