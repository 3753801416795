import {Injectable, Injector} from '@angular/core'
import {industry_type, IOrg} from '@/lib/app/models/org.interface'
import {ActiveOrgService} from '@/org/app/services/active-org.service'
import {OrgService} from '@/org/app/services/org.service'
import {AuthService} from '@/lib/app/services/auth.service'
import {ROLE_ID} from '@/lib/app/models/role.interface'
import {AuthDestroyerService} from '@/lib/app/services/auth-destroyer.service'
import {ActivatedRoute} from '@angular/router'
import {Dictionary, keyBy} from 'lodash'
import {LocalStorageService} from '@/lib/app/services/local-storage.service'
import {IUser} from '@/lib/app/models/user.interface'
import {UserStore} from '@/org/app/stores/user.store'
import {CaseStatus} from '@/lib/app/models/case.interface'
import {FeatureStore} from '@/lib/app/stores/feature.store'

@Injectable({
  providedIn: 'root',
})
export class ActiveOrgStore {
  org: IOrg
  enterpriseOrgs: IOrg[]

  constructor(
    private _auth: AuthService,
    private _activeOrgService: ActiveOrgService,
    private _destroyer: AuthDestroyerService,
    private _orgService: OrgService,
    private _route: ActivatedRoute,
    private _localStorage: LocalStorageService,
    private _userStore: UserStore,
    private _injector: Injector,
  ) {
    this._destroyer.registerHandler(this)
  }

  async destroy() {
    this.org = null
    this.enterpriseOrgs = null
  }

  get activeOrg(): IOrg {
    return this.org
  }

  set activeOrg(org: IOrg) {
    this.org = org
  }

  get activeOrgId(): IOrg['id'] {
    return this.org?.id
  }

  get enterpriseOrgsList(): IOrg[] {
    return this.enterpriseOrgs
  }

  get enterpriseOrgsById(): Dictionary<IOrg> {
    return keyBy(this.enterpriseOrgs, 'id')
  }

  get users(): IUser[] {
    return this._userStore.items
  }

  get preferredCaseStatus() {
    const _featureStore = this._injector.get(FeatureStore)
    if (this.activeOrg.industry_type === industry_type.FUNERAL_HOME) {
      return CaseStatus.STATUS_AFTER_CARE
    }

    if (this.activeOrg.industry_type === industry_type.FINANCIAL_INSTITUTION && _featureStore.isPrePlanningEnabled) {
      return CaseStatus.STATUS_PRE_PLANNER
    }

    return CaseStatus.STATUS_AFTER_CARE
  }

  async fetchAndDetermineActiveOrg() {
    if (this._auth.user.role.id === ROLE_ID.ENTERPRISE_ADMIN) {
      await this.fetchEnterpriseOrgs()
      this._determineActiveEnterpriseOrg()
      this._localStorage.setUserState('org', 'activeOrgId', this.activeOrgId)
      await this._userStore.pullAll(this.activeOrgId)
    } else {
      await Promise.all([this.fetchAuthUserOrgAndSetActive(), this._userStore.pullAll(this._auth.user.org_id)])
    }
  }
  async fetchAuthUserOrgAndSetActive() {
    this.activeOrg = await this._orgService.fetchById(this._auth.user.org_id)
  }

  async fetchEnterpriseOrgs() {
    this.enterpriseOrgs = await this._activeOrgService.enterprise()
  }

  private _determineActiveEnterpriseOrg() {
    // first, see if there is a query param for org id and try to set the active org to it
    const queryParamOrgId = this._route.snapshot.queryParamMap.get('org_id')
    if (queryParamOrgId && this.enterpriseOrgsById[queryParamOrgId]) {
      console.log('setting active org via query param')
      this.activeOrg = this.enterpriseOrgsById[queryParamOrgId]
      return
    }

    // otherwise, if we have an org in local storage, try to set the active org to that
    const localStorageOrgId = this._localStorage.getUserState('org', 'activeOrgId')
    if (localStorageOrgId && this.enterpriseOrgsById[localStorageOrgId]) {
      console.log('setting active org via local storage', localStorageOrgId)
      this.activeOrg = this.enterpriseOrgsById[localStorageOrgId]
      return
    }

    // otherwise, use the first available org
    if (!this.enterpriseOrgs.length) {
      throw new Error('Cannot set active org because enterpriseOrgs is empty')
    }
    console.log('setting active org using first org')
    this.activeOrg = this.enterpriseOrgs[0]
  }
}
