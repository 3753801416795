import {Injectable} from '@angular/core'
import {BehaviorSubject, lastValueFrom} from 'rxjs'
import {HttpClient, HttpContext} from '@angular/common/http'
import {environment} from '@/lib/environments/environment'
import {map} from 'rxjs/operators'
import {IAuthUser} from '@/lib/app/models/auth-user.interface'
import {AuthDestroyerService, IDestroyable} from '@/lib/app/services/auth-destroyer.service'
import {ERROR_HANDLERS} from '@/lib/app/interceptors/error.interceptor'
import {CookieService} from 'ngx-cookie-service'
import {includes} from 'lodash'
import {DISABLE_PENDING_REQUEST_DETECTION} from '@/lib/app/interceptors/pending-requests.interceptor'
import * as segment from '@/org/app/stores/segment.store'

export const SIGNUP_TYPES = {invite: 'invite', self: 'self'}
export type signUpType = typeof SIGNUP_TYPES.invite | typeof SIGNUP_TYPES.self

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IDestroyable {
  private readonly _user = new BehaviorSubject<IAuthUser>(null)
  readonly user$ = this._user.asObservable()
  get user(): IAuthUser {
    return this._user.getValue()
  }
  set user(val: IAuthUser) {
    this._user.next(val)
  }

  private readonly _token = new BehaviorSubject<string>(null)
  readonly token$ = this._token.asObservable()

  public get token(): string {
    return this._token.getValue()
  }

  private setToken(val: string) {
    const hostName = window.location.host

    if (includes(environment.endUserHostNames, hostName)) {
      this.setCookie(val, 'end-user-token')
    }

    if (includes(environment.businessHostNames, hostName)) {
      this.setCookie(val, 'business-token')
    }

    if (includes(environment.adminHostNames, hostName)) {
      this.setCookie(val, 'admin-token')
    }

    localStorage.removeItem('auth-token')

    this._token.next(val)
  }

  private setCookie(val: string, tokenName: string) {
    if (val) {
      this.cookieService.set(tokenName, val, {expires: 7, path: '/', secure: true, sameSite: 'Strict'})
    } else {
      this.cookieService.delete(tokenName, '/')
    }
  }

  private setUser(user: IAuthUser): IAuthUser {
    if (!this.user) {
      this.user = user
    } else {
      Object.assign(this.user, user)
    }

    if (includes(environment.businessHostNames, window.location.host)) {
      segment.identifyUser(user)
    }

    return this.user
  }

  constructor(
    private _destroyer: AuthDestroyerService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    _destroyer.registerHandler(this, true)

    const hostName = window.location.host

    if (includes(environment.endUserHostNames, hostName)) {
      this.setToken(cookieService.get('end-user-token'))
    }

    if (includes(environment.businessHostNames, hostName)) {
      this.setToken(cookieService.get('business-token'))
    }

    if (includes(environment.adminHostNames, hostName)) {
      this.setToken(cookieService.get('admin-token'))
    }
  }

  login(data: any): Promise<any> {
    // disable 403 error handling on this request (error.interceptor)
    const context = new HttpContext().set(ERROR_HANDLERS, {401: null})
    return this.http
      .post(`${environment.api}/v2/auth/login`, data, {context})
      .pipe(
        map(res => {
          this.setToken(res['token'])
          this.setUser(res['user'])
        })
      )
      .toPromise()
  }

  verifyTfa(token: string, code: string): Promise<any> {
    // disable 401 error handling on this request (error.interceptor)
    const context = new HttpContext().set(ERROR_HANDLERS, {401: null})
    return this.http
      .post(
        `${environment.api}/v2/auth/verify-tfa`,
        {
          token,
          code,
        },
        {context}
      )
      .pipe(
        map(res => {
          this.setToken(res['token'])
        })
      )
      .toPromise()
  }

  resendTfa(tfaToken: string, tfaType: 'phone' | 'email'): Promise<any> {
    return this.http
      .post(`${environment.api}/v2/auth/resend-tfa`, {
        token: tfaToken,
        tfa_type: tfaType,
      })
      .toPromise()
  }

  async logout() {
    await this._destroyer.destroy()
  }

  orgUserSignup(data: any): Promise<any> {
    const obs = this.http.post(`${environment.api}/v2/auth/signup/user-invite`, data).pipe(
      map(res => {
        this.setToken(res['token'])
      })
    )
    return lastValueFrom(obs)
  }

  fetchUser(): Promise<IAuthUser> {
    return this.http
      .get(`${environment.api}/v2/auth/user`)
      .pipe(
        map(res => {
          return this.setUser(res['data'])
        })
      )
      .toPromise()
  }

  updateUser(data: Partial<IAuthUser>): Promise<IAuthUser> {
    return this.http
      .put(`${environment.api}/v2/auth/user`, data)
      .pipe(
        map(res => {
          return this.setUser(res['data'])
        })
      )
      .toPromise()
  }

  updateUserTfa(tfaType: IAuthUser['tfa_type']): Promise<IAuthUser> {
    return this.http
      .put(`${environment.api}/v2/auth/user/tfa`, {tfa_type: tfaType})
      .pipe(
        map(res => {
          return this.setUser(res['data'])
        })
      )
      .toPromise()
  }

  sendPhoneVerificationCode(): Promise<any> {
    const obs = this.http.post(`${environment.api}/v2/auth/send_phone_verification_code`, {})
    return lastValueFrom(obs)
  }

  verifyPhoneVerificationCode(code: string): Promise<IAuthUser> {
    const obs = this.http.post(`${environment.api}/v2/auth/verify_phone_verification_code`, {code}).pipe(
      map(res => {
        return this.setUser(res['data'])
      })
    )
    return lastValueFrom(obs)
  }

  changePassword(data: IChangePassword): Promise<any> {
    return this.http.post(`${environment.api}/v2/auth/change_password`, data).toPromise()
  }

  resetPassword(data: IResetPassword): Promise<any> {
    return this.http.post(`${environment.api}/v2/auth/reset_password`, data).toPromise()
  }

  sendResetPasswordEmail(email: string): Promise<any> {
    return this.http.post(`${environment.api}/v2/auth/send_reset_password_email`, {email}).toPromise()
  }

  clearUser() {
    this.user = null
  }

  async destroy() {
    this.setToken(null)
    this.clearUser()
  }

  /**
   * @param ability the action to check
   * @param objectOrType either
   *  1. an object instance of a data model (e.g. a user model):
   *    `auth.can('update', user)`
   *  or,
   *  2. for abilities not related to a particular data model, a string
   *  identifying a data model type (e.g. 'location'):
   *    `auth.can('create', 'user')`
   */
  can(ability: string, objectOrType: object | string): boolean {
    if (typeof objectOrType === 'object') {
      const object = objectOrType
      // checking an ability related to a particular data model
      if (!object['__abilities']) {
        throw `Tried to check ability to '${ability}' on an object, but the object does not have the abilities field`
      }
      return !!object['__abilities'][ability]
    } else if (typeof objectOrType === 'string') {
      // checking an ability not related to a particular data model
      const type = objectOrType
      if (!this.user) {
        throw 'Tried to check user ability but user is not loaded'
      }
      if (!this.user.__abilities) {
        throw 'Tried to check user ability but abilities are not loaded on the user'
      }
      return !!this.user.__abilities[type]?.[ability]
    }

    return false
  }

  fetchHubspotChatToken(): Promise<string> {
    const context = new HttpContext().set(DISABLE_PENDING_REQUEST_DETECTION, true)
    const obs = this.http
      .get(`${environment.api}/v3/business/auth/hst`, {context})
      .pipe(map((res) => res['data']['hst']))
    return lastValueFrom(obs)
  }
}

interface IChangePassword {
  old_password: string
  password: string
  password_confirmation: string
}

interface IResetPassword {
  code: string
  password: string
  password_confirmation: string
}
