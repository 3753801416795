import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'lib-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
})
export class MaintenanceModeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
