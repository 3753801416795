<div class="wrapper-background">
  <img class="mountains-image" src="/assets/mountains.svg" />
  <img class="trees-image" src="./assets/trees.svg" />
  <img class="grass-image" src="./assets/grass.svg" />
  <img class="clouds-image" src="./assets/clouds.svg" />
</div>

<div class="p-5">
  <h1>Something's not right.</h1>
  <p>It looks like you're not authorized to access this. You can:</p>
  <ul>
    <li>go back to the <a href="/">home screen</a>, or</li>
    <li><a routerLink="/auth/login">sign in with a different account</a>.</li>
  </ul>
  <p>The issue might be on our end. If you're having difficulty:</p>
  <ul>
    <li>reach out to us using the live-chat at the bottom of the screen, or</li>
    <li>email us at <a href="mailto:support@cadenceco.com" target="_blank">support&#64;cadenceco.com</a>.</li>
  </ul>
</div>
