import {Injectable} from '@angular/core'

import {filter, find, keyBy} from 'lodash'

import {IFeatureStore} from '@/lib/app/models/feature/feature-store.interface'
import {IFeatureOverride} from '@/lib/app/models/feature/feature-override.interface'
import {
  FEATURE_KEY_BUSINESS_ANALYTICS,
  FEATURE_KEY_CASE_COLLABORATION,
  FEATURE_KEY_MULTILINGUAL,
  FEATURE_KEY_PRE_PLANNING,
  FEATURE_KEY_REPORTS,
  FEATURE_KEY_SMS,
  FEATURE_KEYS,
  IFeature,
  IFeatureByFeature,
} from '@/lib/app/models/feature/features.interface'

import {AuthService} from '@/lib/app/services/auth.service'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'

@Injectable({
  providedIn: 'root',
})
export class FeatureStore implements IFeatureStore {
  constructor(
    private _authService: AuthService,
    private _activeOrg: ActiveOrgStore,
  ) {}

  get features(): IFeature[] {
    return this._authService.user?.features
  }

  get featureOverrides(): IFeatureOverride[] {
    return this._authService.user?.feature_overrides
  }

  get orgId(): number {
    return this._activeOrg.activeOrg?.id
  }

  get isPrePlanningEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_PRE_PLANNING)
  }

  get isReportsEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_REPORTS)
  }

  get isCaseCollaborationEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_CASE_COLLABORATION)
  }

  get isBusinessAnalyticsEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_BUSINESS_ANALYTICS)
  }

  get isSmsEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_SMS)
  }

  get isMultilingualEnabled(): boolean {
    return this.isFeatureEnabled(this.features, this.featureOverrides, FEATURE_KEY_MULTILINGUAL)
  }

  private isFeatureEnabled(
    features: IFeature[],
    featureOverrides: IFeatureOverride[],
    featureKey: FEATURE_KEYS,
  ): boolean {
    const featuresByKey = keyBy(features, 'key')

    const enabled = this.isGlobalFeatureEnabled(featureKey, featuresByKey)
    return !enabled ? this.isFeatureOverridden(featureOverrides, featureKey, featuresByKey) : enabled
  }

  private isGlobalFeatureEnabled(featureKey: FEATURE_KEYS, featuresByKey: IFeatureByFeature): boolean {
    return featuresByKey && featuresByKey[featureKey] ? featuresByKey[featureKey].enabled : false
  }

  private isFeatureOverridden(
    featureOverrides: IFeatureOverride[],
    featureKey: FEATURE_KEYS,
    featuresByKey: IFeatureByFeature,
  ): boolean {
    if (featuresByKey && featuresByKey[featureKey]) {
      const featureId = featuresByKey[featureKey].id

      const featureOverrideForFeatureId = filter(featureOverrides, featureOverride => {
        return featureOverride.feature_id === featureId
      })

      const foundFeatureOverrideForOrg = this.orgId ? find(featureOverrideForFeatureId, ['org_id', this.orgId]) : null

      if (foundFeatureOverrideForOrg) {
        return true
      }
    }

    return false
  }
}
