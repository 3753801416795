import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core'
import {Router} from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AlertModule} from '@/lib/app/components/alert/alert.module'
import {JwtInterceptor} from '@/lib/app/interceptors/jwt.interceptor'
import {ErrorInterceptor} from '@/lib/app/interceptors/error.interceptor'
import {HTTP_INTERCEPTORS} from '@angular/common/http'
import {RouterModule} from '@angular/router'
import {PublicLayoutComponent} from '@/org/app/layouts/public-layout/public-layout.component'
import {AuthLayoutComponent} from '@/org/app/layouts/auth-layout/auth-layout.component'

import {CoreModule} from '@/lib/app/core.module'
import {PendingRequestsInterceptor} from '@/lib/app/interceptors/pending-requests.interceptor'
import {PendingRequestLoaderModule} from '@/lib/app/components/pending-request-loader/pending-request-loader.module'
import {ConfirmationModalModule} from '@/lib/app/components/confirmation-modal/confirmation-modal.module'
import {MaintenanceModeInterceptor} from '@/lib/app/interceptors/maintenance-mode.interceptor'
import {FormsModule} from '@angular/forms'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {businessAppOnboardingModalComponent} from '@/org/app/components/Onboarding/business-app-onboarding-modal.component'
import {businessAppProductTour} from '@/org/app/components/product-tour/product-tour'
import {FeedbackFormComponent} from '@/org/app/components/feedback-form/feedback-form.component'
import {HubspotChatComponent} from '@/lib/app/components/hubspot-chat/hubspot-chat.component'
import {TfaReminder} from '@/org/app/components/tfa-reminder/tfa-reminder.component'

@NgModule({
  declarations: [AppComponent, PublicLayoutComponent, AuthLayoutComponent],
  imports: [
    CoreModule,
    RouterModule,
    AppRoutingModule,
    AlertModule,
    PendingRequestLoaderModule,
    ConfirmationModalModule,
    FormsModule,
    TooltipModule,
    businessAppOnboardingModalComponent,
    businessAppProductTour,
    FeedbackFormComponent,
    HubspotChatComponent,
    TfaReminder,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestsInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MaintenanceModeInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
