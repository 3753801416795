import {Injectable} from '@angular/core'
import {BaseCollectionStore} from '@/lib/app/stores/base-collection.store'
import {AuthDestroyerService} from '@/lib/app/services/auth-destroyer.service'
import {IOrg} from '@/lib/app/models/org.interface'
import {IUser} from '@/lib/app/models/user.interface'
import {UserService} from '@/org/app/services/user.service'

@Injectable({providedIn: 'root'})
export class UserStore extends BaseCollectionStore<IUser> {
  constructor(destroyer: AuthDestroyerService, private _userService: UserService) {
    super(destroyer)
  }

  async pullAll(orgId: IOrg['id']) {
    this.setItems(await this._userService.fetchUsers(orgId))
  }
}
