import {Injectable} from '@angular/core'
import {IResponseDataObject} from '@/lib/app/models/remote-collection.interface'
import {ICaseMember} from '@/lib/app/models/case-member.interface'
import {ICase} from '@/lib/app/models/case.interface'
import {environment} from '@/lib/environments/environment'
import {HttpClient} from '@angular/common/http'
import {map} from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class OrgCaseMemberService {
  constructor(private _http: HttpClient) {}

  post(caseId: ICase['id']) {
    return this._http
      .post(`${environment.api}/v3/business/cases/${caseId}/org_members`, null)
      .pipe(map(({data}: IResponseDataObject<ICaseMember>) => data))
      .toPromise()
  }

  delete(caseId: ICase['id']): Promise<any> {
    return this._http.delete(`${environment.api}/v3/business/cases/${caseId}/org_members`).toPromise()
  }
}
