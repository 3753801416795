<button class="btn btn-sm btn-outline-primary text-center px-3" (click)="onShow()">
  <i class="fa-regular fa-stars me-2"></i>
  I wish Cadence could&hellip;
</button>

<form
  *ngIf="isShown"
  class="shadow border rounded-5 rounded-bottom-0 bg-white position-absolute bottom-0"
  style="padding: 2rem; left: 1rem; max-width: 493px"
  (ngSubmit)="onSubmit()">
  <div *ngIf="!isComplete">
    <div class="d-flex justify-content-between">
      <h4>I wish Cadence could&hellip;</h4>
      <button class="btn-close" (click)="onClose()"></button>
    </div>

    <p class="text-dark">
      We greatly appreciate your feedback as it plays an important role in enhancing the overall Cadence experience.
    </p>
    <textarea
      #feedback
      [formControl]="feedbackControl"
      placeholder="Type feedback here&hellip;"
      class="form-control mb-3"
      style="height: 114px"></textarea>

    <button type="submit" class="btn btn-primary">Submit feedback</button>
  </div>

  <div *ngIf="isComplete">
    <div class="d-flex justify-content-between">
      <h4>Thank you!</h4>
      <button class="btn-close" (click)="onClose()"></button>
    </div>

    <div class="row">
      <div class="col">
        <img src="/assets/thank-you.png" />
      </div>
      <div class="col">
        <p class="text-dark" style="font-size: 0.875rem">
          Your feedback holds significance to us. We thoroughly examine all feedback and we appreciate your support!
        </p>
        <p class="text-dark" style="font-size: 0.875rem">
          If you require customer support, kindly click the Live Chat button to speak with one of our Support
          Specialists.
        </p>
      </div>
    </div>
  </div>
</form>
