import {NgModule} from '@angular/core'

import {LoginComponent} from './login/login.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component'
import {ResetPasswordComponent} from './reset-password/reset-password.component'
import {TfaComponent} from './tfa/tfa.component'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {InvalidFeedbackModule} from '@/lib/app/components/invalid-feedback/invalid-feedback.module'
import {NgxMaskModule} from 'ngx-mask'
import {CasesModule} from '@/org/app/components/cases/cases.module'
import {SignupComponent} from './signup/signup.component'
import {ToggleUserPasswordVisibilityDirective} from './toggle-user-password-visibility/toggle-user-password-visibility.directive'
import {RequiredIndicatorComponent} from '@/lib/app/components/required-indicator/required-indicator.component'

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TfaComponent,
    SignupComponent,
    ToggleUserPasswordVisibilityDirective,
  ],
  exports: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TfaComponent,
    ToggleUserPasswordVisibilityDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    InvalidFeedbackModule,
    CasesModule,
    RequiredIndicatorComponent,
  ],
})
export class AuthModule {}
