import {ApplicationRef, EmbeddedViewRef, Injectable, TemplateRef} from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // Ensure that the parent application has viewContainerRef instantiated. e.g.
  // public viewContainerRef: ViewContainerRef

  constructor(private _appRef: ApplicationRef) {}

  open(content: TemplateRef<any>) {
    // get the root AppComponent and insert the passed TemplateRef to the AppComponent's view
    const root = this._appRef.components[0].instance
    const embedded = root.viewContainerRef.createEmbeddedView(content) as EmbeddedViewRef<any>
    const modalEl = embedded.rootNodes[0]
    const modal = new window['bootstrap'].Modal(modalEl)
    modalEl.addEventListener('hidden.bs.modal', () => {
      modal.dispose()
      embedded.destroy() // remove the inserted modal nodes from the dom
    })
    modal.show()
    return modal
  }
}
