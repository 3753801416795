import {Component, Input} from '@angular/core'
import {FormGroupDirective} from '@angular/forms'

@Component({
  selector: 'lib-invalid-feedback',
  templateUrl: './invalid-feedback.component.html',
})
export class InvalidFeedbackComponent {
  @Input('for') formControlName: string

  constructor(private formGroupDirective: FormGroupDirective) {}

  showSignInLink: boolean = false

  get errorMessage(): string {
    const fc = this.formGroupDirective.form.controls[this.formControlName]
    if (!fc || !fc.errors) {
      return null
    }

    // error from backend validation
    if (fc.errors['serverError']) {
      return fc.errors['serverError'][0]
    }
    // error from the Validators.required fn
    if (fc.errors['required']) {
      return 'This is required'
    }
    // error from the Validators.email fn
    if (fc.errors['email']) {
      return 'This must be a valid email address'
    }
    // error from the ng-mask Validator
    if (fc.errors['mask']) {
      return 'This is not formatted correctly'
    }
    return 'Invalid'
  }
}
