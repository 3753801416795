<div class="card h-100">
  <div class="card-body">
    <h4>Business details</h4>
    <p *ngIf="case.referred_at" class="text-muted mb-3">
      Invited on
      <abbr tooltip="{{ case.referred_at | date : 'medium' }}" tooltipPlacement="right">
        {{ case.referred_at | date : "mediumDate" }}
      </abbr>
    </p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label class="form-label">Employee</label>
        <select formControlName="handled_by_id" class="form-select form-control-mid is-pii">
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let u of users" [ngValue]="u.id">
            {{ u.name }}
          </option>
        </select>
      </div>

      <div *ngIf="locations.length" class="mb-3">
        <label for="location_id" class="form-label"> Location </label>
        <select id="location_id" name="location_id" formControlName="location_id" class="form-select form-control-mid">
          <option [ngValue]="null">None</option>
          <option *ngFor="let loc of locations" [ngValue]="loc.id">
            <lib-location-name [location]="loc"/>
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-label">Notes</label>
        <textarea class="form-control" formControlName="notes"></textarea>
      </div>

      <div *ngIf="form.dirty">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-link" (click)="resetForm()">Cancel</button>
      </div>
    </form>
  </div>
</div>
