import {Component, OnInit} from '@angular/core'
import {environment} from '@/lib/environments/environment'

@Component({
  selector: 'org-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  constructor() {}

  get isProduction(): boolean {
    return environment.production
  }

  ngOnInit(): void {}
}
