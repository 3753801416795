import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/org/environments/environment'
import {map} from 'rxjs/operators'
import {IOrg} from '@/lib/app/models/org.interface'
import {lastValueFrom} from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ActiveOrgService {
  constructor(private http: HttpClient) {}

  enterprise(): Promise<IOrg[]> {
    const obs = this.http.get(`${environment.api}/v3/business/enterprise/orgs`).pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }
}
