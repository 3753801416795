import {IUser} from '@/lib/app/models/user.interface'
import {ICase} from '@/lib/app/models/case.interface'

export enum CaseCollaborationRequestStatuses {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DRAFT = 'draft',
  BOUNCED = 'bounced',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
}

export interface ICaseCollaborationRequest {
  id: number
  user_id: IUser['id']
  case_id: ICase['id']
  status: CaseCollaborationRequestStatuses
  updated_at: string // datetime
}
