import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms'
import {find, isNil, isNull, mapValues, omitBy} from 'lodash'

type FormatterFn = (a: any) => any
type InitialFiltersValue = {[field: string]: [any, FormatterFn?]}
type FiltersValue = {[field: string]: any}

export class Filters {
  private readonly _initialFiltersValue: InitialFiltersValue
  private readonly _form: UntypedFormGroup

  /**
   * @param initialFiltersValue the "disabled" values for the filters. These
   *   values are saved and used when resetting the filter values (with
   *   this.reset())
   */
  constructor(initialFiltersValue: InitialFiltersValue) {
    this._initialFiltersValue = initialFiltersValue
    this._form = new UntypedFormBuilder().group(mapValues(initialFiltersValue, val => [val[0]]))
  }

  public get form(): UntypedFormGroup {
    return this._form
  }

  public patchForm(data: FiltersValue) {
    this._form.patchValue(
      mapValues(data, (val, field) => {
        // if there is a supplied formatter, use it, otherwise return the raw value
        return this._initialFiltersValue[field]?.[1] && !isNull(val) ? this._initialFiltersValue[field][1](val) : val
      })
    )
  }

  public resetForm() {
    this._form.patchValue(mapValues(this._initialFiltersValue, val => val[0]))
  }

  public hasFilters(field: string = null): boolean {
    if (field) {
      return this.isFilterValActive(this._form.get(field).value)
    }
    return !!find(this._form.value, f => this.isFilterValActive(f))
  }

  /**
   * Generate the query params by returning an object of non-falsy values
   */
  public query(): FiltersValue {
    return omitBy(this._form.value, x => !this.isFilterValActive(x))
  }

  private isFilterValActive(val: any) {
    return !isNil(val) && val !== ''
  }
}
