import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterLink} from '@angular/router'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {TourGuideClient} from '@sjmc11/tourguidejs'
import {FeatureStore} from '@/lib/app/stores/feature.store' // JS

@Component({
  selector: 'business-app-product-tour',
  templateUrl: './product-tour.html',
  standalone: true,
  imports: [],
})
export class businessAppProductTour {
  @Output() tourCompleted = new EventEmitter<void>()
  private tourGuide = null

  constructor(private _features: FeatureStore) {
    this.tourGuide = new TourGuideClient({steps: this.tourguideSteps})
  }

  startProductTour() {
    this.tourguideSetup()
    this.tourGuide.start()
  }

  tourguideSetup() {
    const dashboardTG = {
      target: '#dashboardLink',
      title: 'Measure impact',
      content: 'Over time, the Dashboard will display key metrics, so you can measure the impact Cadence is having.',
      order: '2',
    }

    if (
      this._features.isBusinessAnalyticsEnabled &&
      !this.tourGuide.tourSteps.find(obj => obj.title === 'Measure impact')
    ) {
      this.tourGuide.addSteps([dashboardTG])
    }
  }

  tourguideSteps = [
    {
      title: 'Take the tour',
      content: 'See how to use Cadence in a quick tour. To exit, click the “X” in the corner.\n',
      order: '1',
    },
    {
      target: '#casesLink',
      title: 'View cases',
      content:
        'Keep track of contact information, access permissions, and review pre-need interest within the Case listings page.',
      order: '3',
    },
    {
      target: '#employeeLink',
      title: 'Invite staff',
      content:
        'Admin users can invite employees to Cadence. Once they’ve signed up, employees can invite their own contacts to use the platform.',
      order: '4',
    },
    {
      target: '#resourceLink',
      title: 'Feel confident using Cadence',
      content:
        'The Resources page provides videos about how to use Cadence and marketing materials that can be printed or sent off.',
      order: '5',
    },
    {
      target: '#inviteClient',
      title: 'Invite contacts',
      content:
        'Send an email invitation to your contacts. To sign up, they simply add their email address and create a password.',
      order: '6',
    },
    {
      target: '#accountDropdownBtn',
      title: 'Secure your account',
      content: 'Turn on two-factor authentication and change other settings within your account.',
      order: '7',
    },
  ]
}
