export class Alert {
  id: string = ''
  message: string
  type: AlertType = AlertType.Success
  auto_close: number = 7000

  constructor(init?: Partial<Alert>) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, init)
  }
}

export enum AlertType {
  Success = 0,
  Error = 1,
  Info = 2,
  Warning = 3,
}
