import {Directive, ElementRef, Input, OnChanges, OnDestroy} from '@angular/core'

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy, OnChanges {
  @Input('tooltip') tooltipText: string
  @Input('tooltipDelay') delayMs = 500
  @Input('tooltipPlacement') placement: 'top' | 'bottom' | 'left' | 'right' = 'top'
  @Input() tooltipHtml: boolean = false
  @Input() tooltipTrigger: string = 'hover' // https://getbootstrap.com/docs/5.1/components/tooltips/#options

  private _tooltip: any

  constructor(private _elRef: ElementRef) {}

  get el(): HTMLElement {
    return this._elRef.nativeElement
  }

  ngOnChanges(): void {
    this.render()
  }

  ngOnDestroy() {
    this.destroyTooltip()
  }

  destroyTooltip() {
    this._tooltip?.dispose()
  }

  render() {
    this.destroyTooltip()

    this.el.setAttribute('title', this.tooltipText)
    this.el.setAttribute('data-bs-original-title', this.tooltipText)

    this._tooltip = new window['bootstrap'].Tooltip(this.el, {
      delay: {show: this.delayMs},
      placement: this.placement,
      html: this.tooltipHtml,
      trigger: this.tooltipTrigger,
    })
  }
}
