import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {PendingRequestLoaderComponent} from '@/lib/app/components/pending-request-loader/pending-request-loader.component'

@NgModule({
  declarations: [PendingRequestLoaderComponent],
  exports: [PendingRequestLoaderComponent],
  imports: [CommonModule],
})
export class PendingRequestLoaderModule {}
