import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {Alert, AlertType} from '../../models/alert'
import {AlertService} from '../../services/alert.service'

@Component({selector: 'lib-alert', templateUrl: 'alert.component.html'})
export class AlertComponent {
  @Input() id = 'default-alert'

  constructor(private alertService: AlertService) {}

  get alerts() {
    return this.alertService.alerts
  }

  removeAlert(alert: Alert) {
    this.alertService.removeAlert(alert)
  }

  cssClass(alert: Alert) {
    if (!alert) return

    const alertTypeClass = {
      [AlertType.Success]: ['alert-success', 'text-dark'],
      [AlertType.Error]: ['alert-danger', 'text-dark'],
      [AlertType.Info]: ['alert-info', 'text-dark'],
      [AlertType.Warning]: ['alert-warning', 'text-dark'],
    }
    let additional
    if (alert.type === AlertType.Error && alert.message.toString().includes('<li>')) {
      additional = ['text-start']
    } else {
      additional = ['text-center']
    }
    return alertTypeClass[alert.type].concat(additional)
  }
}
