import {Injectable} from '@angular/core'
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {AuthService} from '@/lib/app/services/auth.service'
import {catchError} from 'rxjs/operators'
import {Router} from '@angular/router'
import {CadenceError} from '@/lib/app/models/cadence-error'
import {AlertService, OFFLINE_ALERT_ID, ONLINE_ALERT_ID} from '@/lib/app/services/alert.service'
import {invoke} from 'lodash'

export const ERROR_HANDLERS = new HttpContextToken<{[code: number]: any}>(() => ({}))

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, public router: Router, public alerts: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const defaultHandlers = {
          0(interceptor: ErrorInterceptor) {
            interceptor._handleNetworkConnectivityError()
            return false
          },
          401(interceptor: ErrorInterceptor) {
            if (interceptor.auth.token) {
              interceptor.auth.logout()
              interceptor.router.navigate(['/auth/login'])
              return false
            }
          },
          403(interceptor: ErrorInterceptor) {
            console.log('@lib/app/interceptors/error.interceptor', '403 response', err)
            interceptor.router.navigate(['/e/403'])
          },
        }
        const handlers = Object.assign({}, defaultHandlers, request.context.get(ERROR_HANDLERS))
        const handlerResult = invoke(handlers, err.status, this)

        if (handlerResult !== false) {
          console.error('@lib/app/interceptors/error.interceptor', 'Received error response', err)
        }

        return throwError(() => new CadenceError(err))
      })
    )
  }

  private _handleNetworkConnectivityError() {
    this.alerts.info(`
                <i class="fa fa-wifi-exclamation me-2"></i>It looks like your internet connection is unstable. Please check your connection and refresh the page.
                <a href="${location.href}">Refresh page</a>
              `,
      {auto_close: 0, id: OFFLINE_ALERT_ID})
    window.addEventListener('online', () => this._removeOfflineAlert(), {once: true})
  }

  private _removeOfflineAlert () {
    this.alerts.removeAlertById(OFFLINE_ALERT_ID)
    this.alerts.info(
      '<i class="fa fa-wifi me-2 text-success"></i> Your internet connection was restored',
      {auto_close: 3000, id: ONLINE_ALERT_ID}
    )
  }
}



