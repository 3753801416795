export enum ROLE_ID {
  ADMIN = 1,
  INDIVIDUAL = 2,
  BUSINESS_ADMIN = 3,
  BUSINESS_USER = 4,
  ENTERPRISE_ADMIN = 5,
  SALES = 6,
}

export interface IRole {
  id: ROLE_ID
  name: string
  slug: string
}
