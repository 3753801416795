import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {TempFileUploaderComponent} from '@/lib/app/components/temp-file-uploader/temp-file-uploader.component'

@NgModule({
  declarations: [TempFileUploaderComponent],
  exports: [TempFileUploaderComponent],
  imports: [CommonModule],
})
export class TempFileUploaderModule {}
