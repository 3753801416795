import {Injectable} from '@angular/core'
import {indexOf, invokeMap} from 'lodash'

@Injectable({providedIn: 'root'})
export class AuthDestroyerService implements IDestroyableCollection {
  handlers: IDestroyable[] = []

  registerHandler(handler: IDestroyable, isPriority: boolean = false): void {
    const i = indexOf(this.handlers, handler)

    console.log('@lib/app/services/auth-destroyer.service', 'registering destroy handler', handler.constructor.name)

    if (i > -1) {
      this.handlers.splice(i, 1)
    }
    isPriority ? this.handlers.unshift(handler) : this.handlers.push(handler)
  }

  async destroy(): Promise<void> {
    console.log('@lib/app/services/auth-destroyer.service', 'destroying all', this.handlers)

    await Promise.all(invokeMap(this.handlers, 'destroy'))
  }
}

export interface IDestroyable {
  destroy(): Promise<void>
}

export interface IDestroyableCollection extends IDestroyable {
  handlers: IDestroyable[]
  registerHandler(handler: IDestroyable, isPriority: boolean): void
}
