import {Pipe, PipeTransform} from '@angular/core'
import {formatPhone} from '@/lib/app/models/phone.model'
/*
 * Formats a phone number in the format of 3334445555 to 333 444 5555
 */
@Pipe({name: 'phone'})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value
    }

    return formatPhone(value)
  }
}
