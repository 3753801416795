import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ILocation} from '@/org/app/models/location.interface'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'

@Component({
  selector: 'lib-location-name',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './location-name.component.html',
})
/*
 * It's kind of silly now to have a component for this. But we didn't always have 'internal_name'.
 *
 * Check the git history.
 */
export class LocationNameComponent {
  @Input() location: ILocation


}
