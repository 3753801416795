import {Component, Input} from '@angular/core'
import {ICase} from '@/lib/app/models/case.interface'
import {AlertService} from '@/lib/app/services/alert.service'
import {FormBuilder, ReactiveFormsModule} from '@angular/forms'
import {CaseStore} from '@/org/app/stores/case.store'
import {CaseService} from '@/org/app/services/case.service'
import {DatePipe, NgForOf, NgIf} from '@angular/common'
import {LocationNameComponent} from '@/lib/app/components/location-name/location-name.component'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {IOrg} from '@/lib/app/models/org.interface'
import {ILocation} from '@/org/app/models/location.interface'
import {LocationStore} from '@/lib/app/stores/location.store'
import {IUser} from '@/lib/app/models/user.interface'

@Component({
  selector: 'org-case-business-info',
  templateUrl: './case-business-info.component.html',
  standalone: true,
  imports: [DatePipe, LocationNameComponent, NgForOf, NgIf, ReactiveFormsModule, TooltipModule],
})
export class CaseBusinessInfoComponent {
  @Input() case: ICase
  @Input() org: IOrg
  @Input() users: IUser[]
  @Input() locations: ILocation[]

  form = this._fb.group({
    handled_by_id: [null],
    location_id: [null],
    notes: [''],
  })

  constructor(
    private _fb: FormBuilder,
    private _caseStore: CaseStore,
    private _caseService: CaseService,
    private _alerts: AlertService,
    public locationStore: LocationStore
  ) {}

  ngOnInit(): void {
    this.resetForm()
  }

  async onSubmit() {
    try {
      const dcase = await this._caseService.update(this.case.id, this.form.value)
      this._caseStore.upsert(dcase)
    } catch (e) {
      this._alerts.error(e)
      return
    }
    this.resetForm()
  }

  onCancelEdit() {
    this.resetForm()
  }

  resetForm() {
    this.form.reset(this.case)
  }
}
