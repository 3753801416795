import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {UnauthorizedComponent} from '@/lib/app/components/errors/unauthorized/unauthorized.component'

// reusable route to use in apps top-level routing module
export const errorRoutes = [
  {
    path: 'e',
    loadChildren: () => import('@/lib/app/components/errors/errors.module').then(m => m.ErrorsModule),
  },
]

const routes: Routes = [{path: '403', component: UnauthorizedComponent}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsRoutingModule {}
