<div class="px-3">
  <div class="text-center py-5">
    <img
      style="height: 48px"
      class="img-fluid"
      src="/assets/logo-labelled.svg"
      role="button"
      routerLink="/auth/login" />
  </div>
  <div class="mx-auto max-w-500 card p-3 p-sm-4">
    <div class="card-body text-center">
      <h3>Temporarily unavailable</h3>
      <p>We are performing maintenance on our website. We should be back online in a few minutes.</p>
      <a class="" href="/">Click here to try again</a>
    </div>
  </div>
</div>
