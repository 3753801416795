import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/lib/environments/environment'
import {lastValueFrom} from 'rxjs'
import {map} from 'rxjs/operators'
import {ITempUploadedFile} from '@/lib/app/models/temp-uploaded-file.interface'

@Injectable({
  providedIn: 'root',
})
export class TempFileUploadService {
  constructor(private _http: HttpClient) {}

  upload(file: File): Promise<ITempUploadedFile> {
    const fd = new FormData()
    fd.append('file', file)
    const obs = this._http.post(`${environment.api}/v2/files/temp`, fd).pipe(map(res => res['data']))
    return lastValueFrom(obs)
  }
}
