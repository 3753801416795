<div class="dropdown d-inline">
  <button class="btn btn-lg btn-light py-0 px-2 border-0" data-bs-toggle="dropdown">
    <i class="fa-solid fa-ellipsis-v"></i>
  </button>
  <ul class="dropdown-menu dropdown-menu-end">
    <li>
      <span [tooltip]="canDelete ? '' : 'Case cannot be deleted. Invitation has been accepted.'">
        <button class="dropdown-item" (click)="onDelete()" [disabled]="!canDelete">
          <i class="far fa-trash me-2" [ngClass]="{'text-danger': canDelete}" ></i>Delete case...
        </button>
      </span>
    </li>
  </ul>
</div>
