<div
  *ngIf="progress"
  class="lib-pending-request-loader progress position-fixed top-0 w-100"
  style="height: 4px; z-index: 1080">
  <div
    class="progress-bar"
    role="progressbar"
    [style.width.%]="progress"
    [attr.aria-valuenow]="progress"
    aria-valuemin="0"
    aria-valuemax="100"></div>
</div>
