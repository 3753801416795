import {enableProdMode} from '@angular/core'
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'
import {AppModule} from './app/app.module'
import {environment} from '@/org/environments/environment'

if (environment.production) {
  enableProdMode()
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://f9d6f9c521e14c2aa96c1c8aa507212e@o4504249244123136.ingest.sentry.io/4505314175746048',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [new RegExp(`^${environment.api}`)],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
