import {Injectable} from '@angular/core'
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http'
import {Observable} from 'rxjs'
import {AuthService} from '@/lib/app/services/auth.service'
import {environment} from '@/lib/environments/environment'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const token = this.authService.token
    const isLoggedIn = !!token
    const isApiUrl = request.url.startsWith(environment.api)
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    }

    return next.handle(request)
  }
}
