import {Injectable} from '@angular/core'
import {AlertService} from '@/lib/app/services/alert.service'
import {UntypedFormGroup} from '@angular/forms'
import {CadenceError} from '@/lib/app/models/cadence-error'
import {cloneDeep, each} from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  constructor(private _alerts: AlertService) {}

  /**
   * Handles error data by attaching any validation errors from the backend
   * to associated form controls.
   *
   * Any remaining errors are displayed in an alert.
   *
   * @param form
   * @param error
   * @param messageReplacements
   */
  handle(form: UntypedFormGroup, error: CadenceError, messageReplacements?: {[from: string]: string}) {
    if (!error.validationErrors) {
      this._alerts.error(error.getMessage(messageReplacements))
      return
    }
    const validationErrors = cloneDeep(error.validationErrors)
    each(validationErrors, (serverError, field) => {
      const formControl = form.get(field)
      if (formControl) {
        formControl.setErrors({serverError})
        delete validationErrors[field]
      }
    })

    form.markAllAsTouched()

    // for errors that did not get used by a form control, show them in an alert
    if (Object.keys(validationErrors).length) {
      this._alerts.error(CadenceError.makeMessageFor(validationErrors))
    }
  }
}
