import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'lib-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
