export const FEATURE_KEY_PRE_PLANNING = 'pre_planning'
export const FEATURE_KEY_CASE_COLLABORATION = 'case_collaboration'
export const FEATURE_KEY_REPORTS = 'reports'
export const FEATURE_KEY_SMS = 'sms'
export const FEATURE_KEY_BUSINESS_ANALYTICS = 'business_analytics'
export const FEATURE_KEY_MULTILINGUAL = 'multilingual'
export const FEATURE_KEY_TRANSMIT_REQUESTS = 'transmit_requests'

export type FEATURE_KEYS =
  | typeof FEATURE_KEY_PRE_PLANNING
  | typeof FEATURE_KEY_CASE_COLLABORATION
  | typeof FEATURE_KEY_REPORTS
  | typeof FEATURE_KEY_SMS
  | typeof FEATURE_KEY_BUSINESS_ANALYTICS
  | typeof FEATURE_KEY_MULTILINGUAL
  | typeof FEATURE_KEY_TRANSMIT_REQUESTS

export interface IFeature {
  id: number
  key: FEATURE_KEYS
  enabled: boolean
}

export interface IFeatureByFeature {
  [feature: string]: IFeature
}
