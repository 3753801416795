import {RouterModule, Routes} from '@angular/router'
import {EditComponent} from './edit/edit.component'
import {IndexComponent} from './index/index.component'
import {NgModule} from '@angular/core'
import {CaseDetailsComponent} from './edit/case-details/case-details.component'

export const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: {
      routeReuseKey: 'cases',
    },
  },
  {
    path: ':id',
    redirectTo: ':id/edit',
  },
  {
    path: ':id',
    component: EditComponent,
    children: [{path: 'edit', component: CaseDetailsComponent}],
  },
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CasesRoutingModule {}
