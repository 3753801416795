<!--Only show this section if the org allows org employees to access the case, -->
<!--AND if the auth user is a business user todo test enterprise users -->
<ng-container *ngIf="!isEnterpriseAdmin && doesOrgAllowOrgUserAccessToCases && isAuthUserOrgUser">

  @if(canEnableCollaboAccessWithoutRequest) {
  <div>
    <div class="d-flex align-items-start mb-2">
      <h4>Edit your contact's case</h4>
    </div>
    <div class="form-check form-switch ps-0 d-flex align-items-start mb-4">
      <label for="access_switch" class="form-check-label me-4">
        <!-- (click)="false"> allows mobile users to tap on the icon to pop the tooltip without triggering the switch -->
        <ng-container *ngIf="!isAfterCareCase"
          >Turn ON to edit your contact's case information on their behalf within Legacy Planner.</ng-container
        >
        <ng-container *ngIf="isAfterCareCase"
          >Turn ON to edit your contact's case information on their behalf within Executor Assistant.</ng-container
        >
      </label>
      <input
        *ngIf="showInput"
        type="checkbox"
        class="ms-1 form-check-input form-check-input-status-text flex-shrink-0"
        data-form-check-status-on="On"
        data-form-check-status-off="Off"
        role="switch"
        [ngModel]="isAuthUserMemberOfCase"
        (change)="handleAccessChange()"
        id="access_switch" />
    </div>
  </div>
  } @else {
    <div>
      <div class="d-flex mb-3 justify-content-between align-items-center">
        <div class="me-3">
          <ng-container *ngIf="isAfterCareCase"
          >Send a request to your contact to edit your contacts case information in Executor Assistant.</ng-container
          >
          <ng-container *ngIf="!isAfterCareCase"
          >Send a request to your contact to edit your contacts case information in Legacy Planner.</ng-container
          >
        </div>
        <div class="flex-shrink-0">
          <ng-container *ngIf="canSendRequest">
            <button class="btn btn-outline-primary" (click)="requestCaseAccess()">Send request</button>
          </ng-container>

          <div
            *ngIf="isAuthUserMemberOfCase || authUserCollabRequest"
            [ngClass]="{
                'text-info':    authUserCollabRequest?.status === CaseCollaborationRequestStatuses.PENDING,
                'text-success': authUserCollabRequest?.status === CaseCollaborationRequestStatuses.ACCEPTED || !authUserCollabRequest,
                'text-danger':  authUserCollabRequest?.status === CaseCollaborationRequestStatuses.REJECTED,
             }">
            {{ discoverLabelForRequestStatus(authUserCollabRequest?.status) }}
          </div>
        </div>
      </div>
    </div>
  }

  <div *ngIf="canResendRequest" class="mt-3">
    <button class="btn btn-outline-primary" (click)="requestCaseAccess()">Resend request</button>
  </div>

  <div *ngIf="isAuthUserMemberOfCase">
    <button *ngIf="!canEnableCollaboAccessWithoutRequest" class="btn btn-primary me-3" (click)="handleAccessChange()">
      Remove permission
    </button>

    <a [href]="enduserCaseUrl" target="_blank" class="btn btn-outline-primary">
      <i class="far fa-external-link me-2"></i>
      <ng-container *ngIf="isAfterCareCase">Open Executor Assistant</ng-container>
      <ng-container *ngIf="!isAfterCareCase">Open Legacy Planner</ng-container>
    </a>
  </div>
</ng-container>
