import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/lib/environments/environment'
import {map} from 'rxjs/operators'
import {IOrg} from '@/lib/app/models/org.interface'
import {lastValueFrom} from 'rxjs'
import {PaymentMethod} from '@stripe/stripe-js'
import {IStripeInvoice} from '@/lib/app/models/stripe-invoice.interface'

@Injectable({
  providedIn: 'root'
})
export class OrgService {
  constructor(private http: HttpClient) {
  }

  fetchById(id: IOrg['id']): Promise<IOrg> {
    const obs = this.http.get(`${environment.api}/v3/business/orgs/${id}`).pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }

  update(id: IOrg['id'], data: Partial<IOrg>): Promise<IOrg> {
    const obs = this.http.patch(`${environment.api}/v3/business/orgs/${id}`, data).pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }

  updatePaymentMethod(id: IOrg['id'], paymentMethodId: PaymentMethod['id']): Promise<IOrg> {
    const obs = this.http
      .put(`${environment.api}/v3/business/orgs/${id}/payment-method`, {payment_method_id: paymentMethodId})
      .pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }

  fetchInvoices(id: IOrg['id']): Promise<IStripeInvoice[]> {
    const obs = this.http.get(`${environment.api}/v3/business/orgs/${id}/invoices`).pipe(map(res => res['data']))

    return lastValueFrom(obs)
  }
}
