<div class="d-flex flex-column min-vh-100" style="background: #ebe9ef">
  <main class="flex-grow-1">
    <div class="alert-container">
      <lib-alert></lib-alert>
    </div>

    <router-outlet></router-outlet>
  </main>

  <lib-confirmation-modal></lib-confirmation-modal>
  <lib-hubspot-chat *ngIf="isProduction"></lib-hubspot-chat>
</div>
