import {IProofOfDeath} from './proof-of-death.interface'
import {ICaseMember} from './case-member.interface'
import {IUser} from './user.interface'
import {ILocation} from '@/org/app/models/location.interface'
import {IOrg} from '@/lib/app/models/org.interface'
import {ICaseTask} from '@/lib/app/models/case-task.interface'

export enum CasePaymentStatus {
  PAYMENT_STATUS_PENDING = 'pending',
  PAYMENT_STATUS_IN_PROGRESS = 'in-progress',
  PAYMENT_STATUS_DONE = 'done',
  PAYMENT_STATUS_FAILED = 'failed',
}

export enum CaseStatus {
  STATUS_PRE_PLANNER = 1,
  STATUS_PRE_PLANNER_LOCKED = 2,
  STATUS_AFTER_CARE = 3,
  STATUS_AFTER_CARE_LOCKED = 4,
}

export interface ICase {
  id: number
  name: string
  first_name: string
  last_name: string
  is_test: boolean
  status: CaseStatus
  notes?: string
  date_of_death: Date
  date_of_funeral: Date
  created_at: Date
  serviced_at: Date
  referred_at: Date
  cs1_last_reminder_sent_at?: Date
  cs1_review_reminder_date?: Date
  pre_need_lead_created_at?: Date
  pre_need_lead_contact_at?: Date
  pre_need_lead_contact_until?: Date
  paid: boolean
  payment_status: CasePaymentStatus
  requires_concierge: boolean
  creator_id?: number
  creator?: IUser
  handled_by_id?: IUser['id']
  caseHandler?: IUser
  proof_of_death?: IProofOfDeath
  location?: ILocation
  org?: IOrg
  owner?: ICaseMember
  tasks?: ICaseTask[]
  tasks_count?: number
  enduser_custom_tasks_count?: number
  meeting_link?: string
  checkout_url?: string
  members?: ICaseMember[]
  meta:
    | object
    | {
        referred_to_fc_at: string
        internal_notes: string
      }
  pre_need?: {
    b_free_info: string
    b_contact_at: string
    b_contact_notes: string
    b_contact_phone: {ext: null | string; digits: string}
  }
}
