export interface IPromiseWithState extends Promise<any> {
  key: string

  isFulfilled: boolean
  isPending: boolean
  isRejected: boolean
}

/**
 * A noop queryable promise for initializers, leveraged when we want to guarantee a valid state and
 * default isPending as truthy.
 *
 * Use this when your happy path relies on isFulfilled.
 * eg. initial fetch of dataset within an onLoad.
 * ```
 * isLoadedPromise: IPromiseWithState = createNoopPromiseWithQueryableState()
 * ...
 * isLoaded = () => isLoadedPromise.isFulfilled
 * ```
 *
 * When your happy path relies on isPending as falsy, initialize your promise reference without a value.
 * eg. present a spinner upon user interaction.
 * ```
 * isUploading: IPromiseWithState;
 * ...
 * <spinner *ngIf="isUploading.isPending"></spinner>
 * ```
 */
export function createNoopPromiseWithQueryableState(key = ''): IPromiseWithState {
  return extendPromiseWithQueryableState(new Promise<any>(null), key)
}

/**
 * inspired by https://ourcodeworld.com/articles/read/317/how-to-check-if-a-javascript-promise-has-been-fulfilled-rejected-or-resolved
 */
export function extendPromiseWithQueryableState(p: Promise<any>, key = ''): IPromiseWithState {
  if ('isFulfilled' in p) {
    // abort when previously extended
    return p as IPromiseWithState
  }

  let state: 'pending' | 'fulfilled' | 'rejected' = 'pending'

  p.then(
    v => (state = 'fulfilled'),
    e => {
      state = 'rejected'
      throw e
    }
  )

  Object.defineProperty(p, 'key', {get: () => key})
  Object.defineProperty(p, 'isPending', {get: () => state === 'pending'})
  Object.defineProperty(p, 'isFulfilled', {get: () => state === 'fulfilled'})
  Object.defineProperty(p, 'isRejected', {get: () => state === 'rejected'})
  Object.defineProperty(p, 'isComplete', {get: () => this.isFulfilled || this.isRejected})

  return p as IPromiseWithState
}
