import {Component} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterLink} from '@angular/router'
import {AuthService} from '@/lib/app/services/auth.service'

@Component({
  selector: 'tfa-reminder-modal',
  templateUrl: './tfa-reminder.component.html',
  standalone: true,
  imports: [CommonModule, RouterLink],
})
export class TfaReminder {
  constructor(public auth: AuthService) {}
  async confirmHideTFAReminder(): Promise<boolean> {
    const modalEl = document.getElementById('tfa-reminder-modal')
    const modal = new window['bootstrap'].Modal(modalEl)
    const stopRemindersBtn = document.getElementById('tfa-reminder-modal-deny')

    modal.show()

    let resolve
    const promise = new Promise<boolean>(r => {
      resolve = r
    })
    stopRemindersBtn.addEventListener('click', () => {
      resolve(true)
      modal.hide()
    })
    modalEl.addEventListener('hide.bs.modal', () => resolve(false))

    return promise
  }

  async dismissTFAReminder() {
    const confirm = await this.confirmHideTFAReminder()

    if (!confirm) {
      return
    }

    await this.auth.updateUser({meta: {has_dismissed_tfa_warning: true}})
  }
}
