import {inject} from '@angular/core'
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router'
import {Observable} from 'rxjs'
import {AuthService} from '@/lib/app/services/auth.service'

export const authGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const token = inject(AuthService).token
  if (token) {
    return true
  }

  inject(Router).navigate(['/auth/login'], {
    queryParams: {to: state.url},
  })
  return false
}
