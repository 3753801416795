import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {IPaginationMeta} from '@/lib/app/models/paginated.interface'
import {times} from 'lodash'

@Component({
  selector: 'lib-paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent {
  @Input() pagination: IPaginationMeta

  @Output() goToPage = new EventEmitter<number>()
  @Output() nextPage = new EventEmitter<any>()
  @Output() prevPage = new EventEmitter<any>()

  pages(): number[] {
    return times(this.pagination.last_page, n => n + 1)
  }

  shouldShow(p: number) {
    return (
      this.pagination.last_page < 16 ||
      (this.pagination.last_page > 16 &&
        // first or second
        (p == 1 ||
          p == 2 ||
          // last or second last
          p == this.pagination.last_page ||
          p == this.pagination.last_page - 1 ||
          // within 2 of current_page
          p == this.pagination.current_page - 2 ||
          p == this.pagination.current_page - 1 ||
          p == this.pagination.current_page ||
          p == this.pagination.current_page + 1 ||
          p == this.pagination.current_page + 2))
    )
  }
}
