import {AnalyticsBrowser} from '@segment/analytics-next'
import {environment} from '@/org/environments/environment'
import {IAuthUser} from '@/lib/app/models/auth-user.interface'
import {IResource} from '@/org/app/models/resource.interface'
import {IReport} from '@/org/app/models/reports.interface'

const analytics = environment.production ? AnalyticsBrowser.load({writeKey: environment.segment_write_key}) : null

export const identifyUser = (user: IAuthUser) => {
  analytics?.identify(`${user.id}`, {
    first_name: `${user.first_name}`,
    last_name: `${user.last_name}`,
    email: `${user.email}`,
  })
}

export const businessResourcesOpened = (resource: IResource) => {
  analytics?.track('Resource Viewed', {
    topic: resource.topic,
    title: resource.title,
    kind: resource.kind,
    action: resource.action,
  })
}

export const reportDownloaded = (report: IReport) => {
  analytics?.track('Report Downloaded', {
    id: report.id,
    org_id: report.org_id,
    name: report.name,
    type: report.type,
    uploaded_at: report.uploaded_at,
  })
}
