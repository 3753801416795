<div class="d-flex align-items-center mb-3">
  <nav *ngIf="pagination.last_page > 1" class="me-3">
    <ul class="pagination mb-0">
      <li class="page-item" [ngClass]="{disabled: pagination.current_page === 1}">
        <a role="button" class="page-link" (click)="prevPage.emit()">Previous</a>
      </li>
      <ng-container *ngIf="pagination.last_page">
        <li
          class="page-item"
          *ngFor="let p of pages()"
          [ngClass]="{
            active: pagination.current_page === p
          }">
          <a *ngIf="shouldShow(p)" role="button" class="page-link" (click)="goToPage.emit(p)">
            {{ p }}
          </a>
        </li>
      </ng-container>
      <li class="page-item" [ngClass]="{disabled: pagination.current_page === pagination.last_page}">
        <a role="button" class="page-link" (click)="nextPage.emit()" disabled="true">Next</a>
      </li>
    </ul>
  </nav>

  <div>
    <ng-container *ngIf="pagination.last_page === 1"> {{ pagination.total }} items </ng-container>
    <ng-container *ngIf="pagination.last_page > 1 && pagination.total">
      {{ pagination.from }} - {{ pagination.to }} of {{ pagination.total }}
    </ng-container>
  </div>
</div>
