import {map} from 'lodash'

export class CadenceError implements Error {
  name = 'CadenceError'
  error: any
  stack?: string

  constructor(err: any) {
    this.error = err
  }

  public toString = (): string => {
    return this.message
  }

  public getMessage(replacements?: {[from: string]: string}): string {
    return (replacements && this.message in replacements) ? replacements[this.message] : this.message
  }

  get message(): string {
    if (this.hasValidationErrors) {
      return CadenceError.makeMessageFor(this.error.error.errors)
    } else {
      const message = this.error.error?.message || this.error.statusText
      if (message === 'OK') {
        return 'Error'
      }
      return message
    }
  }

  get hasValidationErrors(): boolean {
    return this.error.status === 422 && typeof this.error.error?.errors === 'object'
  }

  get validationErrors(): object {
    return this.error.error?.errors
  }

  static makeMessageFor(validationErrors: object) {
    // these are error messages generated automatically by laravel via request validation
    // they are in the form:
    /*
     {
      errors: {
        fieldName: ["Error message 1", "Error message 2"],
        anotherFieldName: ["Another error"],
      }
     }
    */
    // Just write the first error into the error message, for each field that has an error.
    const errorString = map(validationErrors, errArr => `<li>${errArr[0]}</li>`).join('')
    return `Invalid data: <br><ul class="mb-0">${errorString}</ul>`
  }
}
