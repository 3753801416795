import {ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {PendingRequestsStore} from '@/lib/app/stores/pending-requests.store'

@Component({
  selector: 'lib-pending-request-loader',
  templateUrl: './pending-request-loader.component.html',
})
export class PendingRequestLoaderComponent implements OnInit {
  progress: number

  constructor(
    private _store: PendingRequestsStore //private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._store.progress.subscribe(data => {
      this.progress = data
      //this._cdr.detectChanges()
    })
  }
}
