import {Component, ElementRef, ViewChild} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {UserService} from '@/org/app/services/user.service'
import {AuthService} from '@/lib/app/services/auth.service'
import {defer} from 'lodash'

@Component({
  selector: 'org-feedback-form',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './feedback-form.component.html',
})
export class FeedbackFormComponent {
  public isShown = false
  public isComplete = false
  public feedbackControl = this._fb.control('', Validators.required)
  @ViewChild('feedback') public feedbackEl: ElementRef<HTMLTextAreaElement>

  constructor(private _fb: FormBuilder, private _userService: UserService, private _authService: AuthService) {}

  onShow() {
    this.isShown = true
    defer(() => this.feedbackEl.nativeElement.focus())
  }

  async onSubmit() {
    if (!this.feedbackControl.valid) {
      return
    }

    await this._userService.submitFeedback({
      message: this.feedbackControl.value,
      submitted_at: new Date().toJSON(),
      source: 'business',
    })

    this.isComplete = true
    this.feedbackControl.reset()
  }

  onClose() {
    this.isShown = false
    this.isComplete = false
    this.feedbackControl.reset()
  }
}
