<div class="card h-100">
  <div class="card-body">
    <h4 class="card-title mb-3">Pre-need lead</h4>
    <div class="row mb-3">
      <div class="col-sm-5 text-muted">Lead date</div>
      <div class="col-sm-7">
        <abbr [tooltip]="case.pre_need_lead_created_at | date : 'medium'">
          {{ case.pre_need_lead_created_at | date }}
        </abbr>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-5 text-muted">
        Requested contact
        <i class="far fa-info-circle" tooltip="The contact's preferred time to be contacted about pre-need"></i>
      </div>
      <div
        class="col-sm-7"
        [class.text-muted]="isPreNeedContactAtExpired"
        [class.text-decoration-line-through]="isPreNeedContactAtExpired">
        <abbr [tooltip]="case.pre_need_lead_contact_at | date">{{
          case.pre_need_lead_contact_at | date : "MMM d"
        }}</abbr>
        <ng-container *ngIf="case.pre_need_lead_contact_until">
          -
          <abbr [tooltip]="case.pre_need_lead_contact_until | date">{{
            case.pre_need_lead_contact_until | date : "MMM d"
          }}</abbr>
        </ng-container>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-5 text-muted">Provided phone</div>
      <div class="col-sm-7">
        <ng-container *ngIf="case.pre_need?.b_contact_phone?.digits">
          {{ case.pre_need?.b_contact_phone.digits | phone }}
        </ng-container>
        <span *ngIf="!case.pre_need?.b_contact_phone?.digits" class="text-muted"> (Empty) </span>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-5 text-muted">Notes</div>
      <div class="col-sm-7">
        <div *ngIf="case.pre_need?.b_contact_notes" style="white-space: pre-line">
          {{ case.pre_need?.b_contact_notes }}
        </div>
        <span *ngIf="!case.pre_need?.b_contact_notes" class="text-muted"> (Empty) </span>
      </div>
    </div>
  </div>
</div>
