import {Component, ViewChild, TemplateRef, OnInit, Output, EventEmitter} from '@angular/core'
import {AuthService} from '@/lib/app/services/auth.service'
import {ModalService} from '@/lib/app/services/modal.service'
import {CommonModule} from '@angular/common'
import {RouterLink} from '@angular/router'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {FeatureStore} from '@/lib/app/stores/feature.store'
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

@Component({
  selector: 'business-app-onboarding-modal',
  templateUrl: './business-app-onboarding-modal.component.html',
  standalone: true,
  imports: [CommonModule, RouterLink, TooltipModule],
})
export class businessAppOnboardingModalComponent implements OnInit {
  @ViewChild('modal') private modalCarousel: TemplateRef<any>
  private _modalInstance: any = null
  // @Output() triggerTour = new EventEmitter<any>()

  constructor(
    private _auth: AuthService,
    private _modal: ModalService,
    private _featureStore: FeatureStore,
    private _sanitizer: DomSanitizer,
  ) {}

  onboarding_elements: any = [
    {
      slideType: 'slide',
      imageURL: 'assets/OnboardingImages/onboarding-1.svg',
      title: 'Welcome to Cadence’s business dashboard!',
      description:
        'We’re delighted to have you onboard. Here are just a few ways Cadence will help you and your families in the time ahead.',
    },
    {
      slideType: 'slide',
      imageURL: 'assets/OnboardingImages/onboarding-2.svg',
      title: 'Focus on what you do best',
      description:
        'You don’t need to have all the answers. Cadence helps you extend your support to families after the funeral while protecting your time and attention, reducing burn-out and compassion fatigue.',
    },
    {
      slideType: 'slide',
      imageURL: 'assets/OnboardingImages/onboarding-3.svg',
      title: 'Reduce stress and workload for families',
      description:
        'Executors will spend an average of 320 hours on estate settlement. By using Cadence, you can cut that down to fewer than 100 hours.',
    },
    {
      slideType: 'slide',
      imageURL: 'assets/OnboardingImages/onboarding-4.svg',
      title: 'Go above and beyond without adding to your work',
      description:
        'Cadence not only provides practical support and guidance on estate administration but also the ability to collaborate on estate-related tasks with others, live calls and meetings with Certified Estate Specialists to answer any questions, guided grief support, and more!',
    },
  ]

  setFirstVideoSlide() {
    if (this._featureStore.isPrePlanningEnabled) {
      this.onboarding_elements.unshift({
        slideType: 'video',
        videoURL: this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/-TUvqtcF0mY'),
        title: 'Legacy Planner and Executor Assistant Explainers',
        description:
          'Watch the video below to gain insight into the journey your contacts will embark on with Legacy Planner and Executor Assistant.',
      })
    } else {
      this.onboarding_elements.unshift({
        slideType: 'video',
        videoURL: this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/B_EjO_o7rXE'),
        title: 'What is Executor Assistant and how does it work?',
        description:
          'Watch the video below to gain insight into the journey your contacts will embark on with Executor Assistant.',
      })
    }
  }

  ngOnInit(): void {
    this.setFirstVideoSlide()
    setTimeout(() => {
      this.openModal()
    }, 1000)
  }
  openModal() {
    if (this._auth.user.meta?.has_seen_business_app_on_boarding_modal) {
      return
    }
    this._modalInstance = this._modal.open(this.modalCarousel)
  }

  // Slide controls
  currentSlideIndex = 0
  nextSlide() {
    this.currentSlideIndex++
  }
  prevSlide() {
    this.currentSlideIndex--
  }
  onExit() {
    this._modalInstance.hide()
    // this.triggerTour.emit()

    try {
      this._auth.updateUser({meta: {has_seen_business_app_on_boarding_modal: true}})
    } catch {}
  }
}
