export const environment = {
  production: true,
  api: 'https://api.cadenceco.com',
  clientBase: 'https://app.cadenceco.com',
  endUserHostNames: ['app.cadenceco.com', 'app.cadencecares.io', 'app.cadencecares.ca'],
  businessHostNames: ['business.cadenceco.com', 'business.cadencecares.io', 'business.cadencecares.ca'],
  adminHostNames: ['admin.cadenceco.com'],
  stripeKey:
    'pk_live_51I8WfZLv2TsJesKsCQAnyDBDWeyFto8ltoOw7oAXa6xNDMYnyDFiB13XeJlOLFTagVHYZKueLdB4hDC1S1xBkY7R00rU8tsAHb',
}
