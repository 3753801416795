import {NgModule, Optional, SkipSelf} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BrowserModule} from '@angular/platform-browser'
import {HttpClientModule} from '@angular/common/http'

@NgModule({
  exports: [CommonModule, BrowserModule, HttpClientModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule')
    }
  }
}
