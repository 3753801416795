import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ConfirmationModalComponent} from './confirmation-modal.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {AuthModule} from '@/lib/app/components/auth/auth.module'

@NgModule({
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AuthModule],
})
export class ConfirmationModalModule {}
