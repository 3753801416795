import {Injectable} from '@angular/core'
import {BaseCollectionStore} from '@/lib/app/stores/base-collection.store'
import {ICase} from '@/lib/app/models/case.interface'
import {AuthDestroyerService} from '@/lib/app/services/auth-destroyer.service'
import {CaseService} from '@/org/app/services/case.service'

@Injectable({providedIn: 'root'})
export class CaseStore extends BaseCollectionStore<ICase> {
  constructor(
    public destroyer: AuthDestroyerService,
    private _caseService: CaseService,
  ) {
    super(destroyer)
  }

  async fetchPaginated(params: any = {}) {
    const res = await this._caseService.fetchPaginated(params)
    this.setItems(res.data)
    return res
  }

  async fetchById(id: ICase['id']) {
    const res = await this._caseService.fetchById(id)
    this.upsert(res)
    return res
  }

  async delete(id: ICase['id']) {
    await this._caseService.delete(id)
    this.remove(id)
  }
}
