import {Injectable} from '@angular/core'
import {NavigationEnd, Router} from '@angular/router'
import {ScriptFetcherService} from '@/lib/app/services/script-fetcher.service'
import {defaults} from 'lodash'
import {IGTagEvent} from '@/lib/app/models/gtag-event.interface'

declare let gtag: (...args) => void
declare let dataLayer: any[]

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _fetcher: ScriptFetcherService, private _router: Router) {}

  async initializeAnalytics(analyticsAppIdentifier: string, extraParametersCallback?: () => object) {
    if (!analyticsAppIdentifier) {
      console.log(
        '@lib/app/services/analytics.service',
        'Skipping analytics initialization due to absent app identifier.'
      )
      return
    }

    defaults(window, {
      dataLayer: [],
      gtag() {
        dataLayer.push(arguments)
      },
    })

    gtag('js', new Date())

    await this._fetcher.fetch(`//www.googletagmanager.com/gtag/js?id=${analyticsAppIdentifier}`)

    this._router.events.subscribe((event: NavigationEnd) => {
      if (!(event instanceof NavigationEnd)) {
        return
      }

      const extraParams = extraParametersCallback ? extraParametersCallback() : {}
      gtag('config', analyticsAppIdentifier, {
        page_path: event.urlAfterRedirects,
        ...extraParams,
      })
    })
  }

  public sendEvent(event: IGTagEvent) {
    gtag('event', event.eventName, {...event})
  }
}
