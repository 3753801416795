import {Component, HostListener, OnInit, ViewChild} from '@angular/core'
import {AuthService} from '@/lib/app/services/auth.service'
import {ActivatedRoute, Router} from '@angular/router'
import {environment} from '@/org/environments/environment'
import {IOrg} from '@/lib/app/models/org.interface'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'
import {FeatureStore} from '@/lib/app/stores/feature.store'
import {businessAppProductTour} from '@/org/app/components/product-tour/product-tour'
import {ROLE_ID} from '@/lib/app/models/role.interface'
import {TfaReminder} from '@/org/app/components/tfa-reminder/tfa-reminder.component'

@Component({
  selector: 'org-auth-layout',
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit {
  org: IOrg
  orgId: IOrg['id']
  navItems: {
    label: string
    routerLink: string
    routerLinkOptions?: {exact: boolean}
    inviteButton?: boolean
    tourStepID?: string
  }[] = []
  @ViewChild(businessAppProductTour)
  protected productTour: businessAppProductTour

  @ViewChild(TfaReminder)
  protected tfaReminderModal: TfaReminder

  constructor(
    public auth: AuthService,
    public activeOrg: ActiveOrgStore,
    private _router: Router,
    private _route: ActivatedRoute,
    private _features: FeatureStore,
  ) {}

  async onLogout() {
    const navigated = await this._router.navigate(['/auth/login'])
    if (!navigated) {
      return
    }
    this.auth.logout()
  }

  async ngOnInit() {
    await this._loadApp()
    this.screenWidth = window.innerWidth
    this.setNavItems() // needs to happen after auth user is fetched to determine features available
  }

  private async _loadApp() {
    await this.auth.fetchUser()
    await this.activeOrg.fetchAndDetermineActiveOrg()
    if (window['clarity']) {
      window['clarity']('set', 'is_test', this.activeOrg.org.is_test ? 'true' : 'false')
      window['clarity']('set', 'org_id', this.activeOrg.org.id)
      window['clarity']('set', 'user_id', this.auth.user.id)
    }
  }

  // Screen width
  public screenWidth: number
  @HostListener('window:resize')
  onResize() {
    this.screenWidth = window.innerWidth
  }

  get isUserAndOrgLoaded(): boolean {
    return !!(this.auth.user && this.activeOrg.activeOrg)
  }

  get isActiveOrgSet(): boolean {
    return !!this.activeOrg.activeOrg
  }

  get hasMultipleOrgs() {
    return this.activeOrg.enterpriseOrgsList && this.activeOrg.enterpriseOrgsList.length > 1
  }

  get user() {
    return this.auth.user
  }

  get isProduction(): boolean {
    return environment.production
  }

  get isReportsFeatureEnabled() {
    return this._features.isReportsEnabled
  }

  async onActiveOrgChange(orgId: IOrg['id']) {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {org_id: orgId},
    })
    window.location.reload()
  }

  isSettingsActive() {
    return this._router.isActive('/settings', {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    })
  }

  setNavItems() {
    this.navItems = [
      ...(this._features.isBusinessAnalyticsEnabled &&
      [ROLE_ID.BUSINESS_ADMIN, ROLE_ID.ENTERPRISE_ADMIN].includes(this.user.role.id)
        ? [
            {
              label: 'Dashboard',
              routerLink: '/',
              routerLinkOptions: {
                exact: true,
              },
              tourStepID: 'dashboardLink',
            },
          ]
        : []),
      {
        label: 'Cases',
        routerLink: '/cases',
        tourStepID: 'casesLink',
      },
      {
        label: 'Employees',
        routerLink: '/users',
        tourStepID: 'employeeLink',
      },
      {
        label: 'Resources',
        routerLink: '/resources',
        tourStepID: 'resourceLink',
      },
      ...(this.isReportsFeatureEnabled
        ? [
            {
              label: 'Reports',
              routerLink: '/reports',
              tourStepID: 'reportLink',
            },
          ]
        : []),
      {
        label: 'Invite Contact',
        routerLink: '/invite',
        inviteButton: true,
        tourStepID: 'inviteClient',
      },
    ]
  }
}
