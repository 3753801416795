import {assign, defer} from 'lodash'
import {Injectable} from '@angular/core'
import {AuthDestroyerService, IDestroyable} from '@/lib/app/services/auth-destroyer.service'

@Injectable({providedIn: 'root'})
export class ThemeSwitcherService implements IDestroyable {
  constructor(_destroyer: AuthDestroyerService) {
    _destroyer.registerHandler(this)
  }

  async setTheme(themeName: string = null): Promise<boolean> {
    console.log('@lib/app/services/theme-switcher.service', 'setting theme to', {themeName})

    // Match something like: <link rel="stylesheet" href="styles.a3a9a82a03fc9d7d.css">
    // See: https://stackoverflow.com/questions/68459681/styles-with-inject-false-in-angular-json-do-not-get-the-hashing
    const defaultTheme =
      this._findLinkedAssetWith('[href^="styles."][href$=".css"]') ??
      this._findLinkedAssetWith('[data-rel="default-theme"]')
    const isDefaultThemeActive = !!defaultTheme.rel
    defaultTheme.setAttribute('data-rel', 'default-theme')

    let resolve
    const promise = new Promise<boolean>(r => (resolve = r))

    if (themeName) {
      const defaultThemeHash = /styles\.(\w+)\.css/gi.exec(defaultTheme.href)?.[1] || ''
      const theme = assign(document.createElement('link'), {
        rel: 'stylesheet',
        type: 'text/css',
        // todo: extract cache busting into build process; currently outputs theme file un-hashed
        href: `${themeName}-styles.css?cache_buster=${defaultThemeHash}`,
      })

      theme.setAttribute('data-rel', 'theme')
      theme.onload = () => {
        defaultTheme.rel = '' // prevent default theme from being interpreted as stylesheet
        resolve(true)
      }

      document.head.appendChild(theme)
    } else if (!isDefaultThemeActive) {
      defaultTheme.rel = 'stylesheet' // interpret as stylesheet
      resolve(true)

      // deferred to insert render cycle between applying default theme above and yanking theme out below (on logout)
      defer(() =>
        this._findAllLinkedAssetsWith('[rel="stylesheet"][data-rel="theme"]').forEach(theme => theme.remove())
      )
    }

    return promise
  }

  _findLinkedAssetWith(selector): HTMLLinkElement {
    return document.querySelector<HTMLLinkElement>(`head > link${selector}`)
  }

  _findAllLinkedAssetsWith(selector): NodeListOf<HTMLLinkElement> {
    return document.querySelectorAll<HTMLLinkElement>(`head > link${selector}`)
  }

  async destroy() {
    this.setTheme(null)
  }
}
