import {Injectable} from '@angular/core'
import {Alert, AlertType} from '../models/alert'
import {find} from 'lodash'

@Injectable({providedIn: 'root'})
export class AlertService {
  private _alerts: Alert[] = []

  get alerts(): Alert[] {
    return this._alerts
  }

  success(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Success, message}))
  }

  error(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Error, message}))
  }

  info(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Info, message}))
  }

  warn(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Warning, message}))
  }

  alert(alert: Alert) {
    this.pushAlert(alert)
  }

  clear() {
    this._alerts = []
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return
    this._alerts = this._alerts.filter(x => x !== alert)
  }

  removeAlertById(id: Alert['id']) {
    const a = find(this._alerts, {id})
    if (a) {
      this.removeAlert(a)
    }
  }

  private pushAlert(alert: Alert) {
    if (this._alerts.map(a => a.id).includes(alert.id)) {
      return
    }
    this._alerts = [...this._alerts, alert]
    if (alert.auto_close) {
      setTimeout(() => this.removeAlert(alert), alert.auto_close)
    }
  }
}

export const OFFLINE_ALERT_ID = 'offline'
export const ONLINE_ALERT_ID = 'online'

