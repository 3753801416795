import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '@/lib/environments/environment'
import {map} from 'rxjs/operators'
import {IUser, IUserFeedback} from '@/lib/app/models/user.interface'
import {IOrg} from '@/lib/app/models/org.interface'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
  }

  fetchUsers(orgId: IOrg['id']): Promise<IUser[]> {
    return this.http
      .get(`${environment.api}/v3/business/users`, {params: {org_id: orgId}})
      .pipe(map(res => res['data']))
      .toPromise()
  }

  fetchById(id: IUser['id']): Promise<IUser> {
    return this.http
      .get(`${environment.api}/v3/business/users/${id}`)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  post(data: Partial<IUser>, options: {send_credentials: boolean} = null): Promise<IUser> {
    return this.http
      .post(`${environment.api}/v3/business/users`, {...data, ...options})
      .pipe(map(res => res['data']))
      .toPromise()
  }

  createNoAuthUser(data: Partial<IUser>): Promise<IUser> {
    return this.http
      .post(`${environment.api}/v3/business/users`, {...data})
      .pipe(map(res => res['data']))
      .toPromise()
  }

  put(id: IUser['id'], data: Partial<IUser>): Promise<IUser> {
    return this.http
      .put(`${environment.api}/v3/business/users/${id}`, data)
      .pipe(map(res => res['data']))
      .toPromise()
  }

  delete(id: IUser['id']): Promise<any> {
    return this.http.delete(`${environment.api}/v3/business/users/${id}`).toPromise()
  }

  submitFeedback(feedback: IUserFeedback) {
    return this.http.post(`${environment.api}/v2/auth/submit_feedback`, feedback).toPromise()
  }
}
