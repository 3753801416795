<div *ngIf="isLoaded()">
  <div class="d-flex align-items-center mb-2">
    <h2 class="is-pii mb-0">{{ case.owner.name }}</h2>
    <lib-is-test-indicator
      class="ms-2"
      *ngIf="case.is_test"
      variant="compact"
      info="This is a Demo case for testing purposes. You will not be billed." />
    <org-case-actions [case]="case" class="ms-2" />
  </div>

  <div class="row g-3">
    <div class="col-12 col-lg-6 col-xl-4">
      <ng-container *ngTemplateOutlet="caseInfo" />
    </div>

    <div class="col-12 col-lg-6 col-xl-4">
      <ng-container *ngTemplateOutlet="clientInfo" />
    </div>

    <div class="col-12 col-lg-6 col-xl-4">
      <org-case-business-info [case]="case" [locations]="locations" [org]="org" [users]="users" />
    </div>

    <div *ngIf="case.pre_need_lead_created_at" class="col-12 col-lg-6 col-xl-4">
      <org-pre-need-lead-info [case]="case" />
    </div>
  </div>
</div>

<ng-template #caseInfo>
  <div class="card h-100">
    <div class="card-body">
      <h4>
        <ng-container *ngIf="isAfterCare"> Deceased information</ng-container>
        <ng-container *ngIf="isPrePlanner"> Planner's information</ng-container>
      </h4>
      <form *ngIf="isLoaded()" [formGroup]="form" (ngSubmit)="onDeceasedInfoSubmit()" class="mb-4">
        <div class="row row-cols-sm-auto g-3 align-items-center mb-3">
          <div class="col-12">
            <label for="case-firstname" class="form-label">First name</label>
            <input
              type="text"
              class="form-control form-control-short is-pii"
              id="case-firstname"
              name="first_name"
              formControlName="first_name"
              placeholder="Enter first name of the deceased" />
          </div>

          <div class="col-12">
            <label for="case-lastname" class="form-label">Last name</label>
            <input
              type="text"
              class="form-control form-control-short is-pii"
              id="case-lastname"
              name="last_name"
              formControlName="last_name"
              placeholder="Enter last name of the deceased" />
          </div>
        </div>

        <ng-container *ngIf="isAfterCare">
          <div class="mb-3">
            <label for="case-date-of-death" class="form-label">Date of death</label>
            <input
              type="date"
              min="1800-01-01"
              max="2099-12-31"
              class="form-control form-control-short is-pii"
              id="case-date-of-death"
              name="date_of_death"
              formControlName="date_of_death" />
          </div>

          <div class="my-3">
            <label for="case-date-of-funeral" class="form-label">Date of funeral</label>
            <input
              type="date"
              min="1800-01-01"
              max="2099-12-31"
              class="form-control form-control-short"
              id="case-date-of-funeral"
              name="date_of_funeral"
              formControlName="date_of_funeral" />
          </div>
        </ng-container>

        <ng-container *ngIf="isPrePlanner && activeOrg.activeOrg.industry_type === industry_type.FINANCIAL_INSTITUTION">
          <div class="mb-3">
            <label for="case-date-of-death" class="form-label">Send annual review reminder on:</label>
            <input
              type="date"
              [min]="today"
              max="2099-12-31"
              class="form-control form-control-short is-pii"
              id="next-bus-review-reminder"
              name="next_bus_review_reminder"
              formControlName="cs1_review_reminder_date" />
            <span *ngIf="case.cs1_last_reminder_sent_at" class="text-muted"
              >Last reminder sent on: {{ case.cs1_last_reminder_sent_at | date }}</span
            >
          </div>
        </ng-container>

        <div *ngIf="form.dirty" class="cta-toolbar mt-4">
          <button type="submit" class="btn btn-primary me-4">Save</button>
          <button type="button" class="btn btn-link" (click)="resetDeceasedInfoForm()">Cancel</button>
        </div>
      </form>

      <div *ngIf="isAfterCare" class="mb-3">
        <label class="form-label">
          Proof of death
          <i
            class="fa-solid fa-question-circle"
            [tooltip]="'Statement of Death by Funeral Director, provided as either an image or PDF file.'">
          </i>
        </label>
        @if (!case.proof_of_death) {
          <lib-temp-file-uploader
            (fileUploaded)="onProofOfDeathChange($event)"
            [accept]="['image/*', 'application/pdf']" />
        } @else {
          <div class="d-flex text-nowrap">
            <button class="btn btn-light me-2" (click)="onReplaceProofOfDeath()">
              <i class="fa-solid fa-cloud-upload-alt"></i>
              Replace
            </button>
            <button
              type="button"
              (click)="onDownloadPod(case.proof_of_death.url)"
              class="btn btn-light me-3 text-truncate">
              <i class="fa-solid fa-cloud-download-alt me-1"></i>
              Download "{{ case.proof_of_death.name }}"
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #clientInfo>
  <div class="card h-100">
    <div class="card-body">
      <div *ngIf="_caseInvitationService.isStatusBounced(case)" class="alert alert-danger mb-3">
        <p>
          <i class="fa-regular fa-triangle-exclamation text-danger"></i> The invitation email could not be delivered to
          the contact.
        </p>
        <p class="mb-0">Use the 3 dots below to edit the email and re-send the invitation.</p>
      </div>
      <org-case-client-info [case]="case" (updated)="fetchCase()"></org-case-client-info>
    </div>
  </div>
</ng-template>
