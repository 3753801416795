import {Component, Input, OnInit} from '@angular/core';
import {HubspotService, IHSConversationSettings} from "@/lib/app/services/hubspot.service";
import {IAuthUser} from "@/lib/app/models/auth-user.interface";
import { AuthService } from "@/lib/app/services/auth.service";

@Component({
  selector: 'lib-hubspot-chat',
  templateUrl: './hubspot-chat.component.html',
  standalone: true,
})
export class HubspotChatComponent implements OnInit {
  @Input('authUser') authUser: IAuthUser;

  constructor(
    private _hubspot: HubspotService,
    private _auth: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this._hubspot.load(await this.getConfig())

    this._auth.user$.subscribe(async val => {
      // reload widget on auth user changes
      if (this._hubspot.isHubSpotConversationsLoaded) {
        this._hubspot.loadOrRefreshWidget(await this.getConfig())
      }
    })
  }

  async getConfig(): Promise<IHSConversationSettings> {
    if (!this._auth.user) {
      return {}
    }

    return {
      loadImmediately: false,
      identificationEmail: this.authUser?.email,
      identificationToken: await this._auth.fetchHubspotChatToken(),
    }
  }
}
