import {Component, Input} from '@angular/core'
import {ICase} from '@/lib/app/models/case.interface'
import {NgClass, NgIf} from '@angular/common'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {ConfirmService} from '@/lib/app/services/confirm.service'
import {CaseStore} from '@/org/app/stores/case.store'
import {AlertService} from '@/lib/app/services/alert.service'
import {Router} from '@angular/router'

@Component({
  selector: 'org-case-actions',
  standalone: true,
  imports: [NgIf, TooltipModule, NgClass],
  templateUrl: './case-actions.component.html',
})
export class CaseActionsComponent {
  @Input() case!: ICase

  constructor(
    private _confirm: ConfirmService,
    private _caseStore: CaseStore,
    private _alerts: AlertService,
    private _router: Router
  ) {}

  get canDelete(): boolean {
    return this.case.serviced_at === null
  }

  async onDelete() {
    const memberName = this.case.owner.name
    const confirmed = await this._confirm.stringConfirm(
      'DELETE',
      `Delete case for <strong>${memberName}</strong>? This action cannot be undone.`
    )
    if (!confirmed) {
      return
    }
    try {
      await this._caseStore.delete(this.case.id)
    } catch (e) {
      this._alerts.error(e)
      return
    }
    this._router.navigate(['/cases'])
    this._alerts.info('Case deleted')
  }
}
