import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ICase} from '@/lib/app/models/case.interface'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {PhoneModule} from '@/lib/app/modules/phone.module'
import {isPreNeedContactAtExpired} from '@/lib/app/domain/preneed'

@Component({
  selector: 'org-pre-need-lead-info',
  standalone: true,
  imports: [CommonModule, TooltipModule, PhoneModule],
  templateUrl: './pre-need-lead-info.component.html',
})
export class PreNeedLeadInfoComponent {
  @Input() case: ICase

  public get isPreNeedContactAtExpired(): boolean {
    return isPreNeedContactAtExpired(this.case.pre_need_lead_created_at, this.case.pre_need_lead_contact_at)
  }
}
