<div>
  <div class="alert alert-secondary alert-dismissible rounded-0 border-start-0 border-end-0 border-top-0 mb-0">
    <div class="text-center">
      Two-factor authentication has not been enabled on this account. Please
      <a routerLink="/settings/security"> click here to enable 2FA.</a>
    </div>
    <button (click)="dismissTFAReminder()" type="button" class="btn-close" aria-label="Close"></button>
  </div>

  <div id="tfa-reminder-modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog mt-2">
      <div class="modal-content shadow-lg">
        <div class="modal-header">
          <h4 class="modal-title">Security warning</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-4">
          <div id="tfa-reminder-modal-text">
            <p>
              Two-factor authentication is an important part of keeping your Cadence business account secure, protecting
              your user data.
            </p>
          </div>
        </div>
        <div class="d-flex p-3 justify-content-between align-items-end">
          <div class="">
            <button id="tfa-reminder-modal-deny" type="button" class="btn btn-link ps-0">Ignore</button>
          </div>
          <div class="d-flex flex-column">
            <button
              id="tfa-reminder-modal-setup"
              type="button"
              class="btn btn-primary px-4"
              data-bs-dismiss="modal"
              routerLink="settings/security">
              Enable 2FA now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
