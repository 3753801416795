import { Injectable } from '@angular/core';
import {ScriptFetcherService} from "@/lib/app/services/script-fetcher.service";

export interface IHSConversationSettings {
  loadImmediately?: boolean
  identificationEmail?: string
  identificationToken?: string
}

export interface IHSWidget {
  load(): void
  refresh(): void
  status(): {loaded: boolean}
  open(): void
}

declare namespace window {
  let hsConversationsSettings: IHSConversationSettings;
  let hsConversationsOnReady: Function[]
  let HubSpotConversations: {widget: IHSWidget}
}

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  constructor(private _fetcher: ScriptFetcherService) {}

  get isHubSpotConversationsLoaded() {
    return !!window.HubSpotConversations
  }

  async load(config: IHSConversationSettings = {loadImmediately: true}) {
    this.updateHubspotConfigWith(config)

    if (!await this._fetcher.fetch(`//js-na1.hs-scripts.com/8733346.js`)) {
      return
    }

    window.HubSpotConversations // load() when already loaded, otherwise: queue on-ready handler
      ? this.loadOrRefreshWidget(config)
      : window.hsConversationsOnReady = [() => this.loadOrRefreshWidget(config)];
  }

  loadOrRefreshWidget(config: IHSConversationSettings) {
    this.updateHubspotConfigWith(config)

    const widget = window.HubSpotConversations.widget
    widget.status().loaded
      ? widget.refresh()
      : widget.load()
  }

  updateHubspotConfigWith(config: IHSConversationSettings) {
    if (!config.identificationEmail || !config.identificationToken) {
      delete config.identificationEmail
      delete config.identificationToken
    }

    window.hsConversationsSettings = Object.assign(
      window.hsConversationsSettings || {},
      config)
  }
}
