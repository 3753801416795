<div id="confirmation-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog mt-2">
    <div class="modal-content shadow-lg">
      <div class="modal-body py-4">
        <div id="confirmation-modal-text"></div>
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button id="confirmation-modal-deny" type="button" class="btn btn-outline-primary me-2" data-bs-dismiss="modal">
          Cancel
        </button>
        <button id="confirmation-modal-confirm" type="button" class="btn btn-primary px-4">OK</button>
      </div>
    </div>
  </div>
</div>

<div id="password-confirmation-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog mt-2">
    <div class="modal-content shadow-lg">
      <div class="modal-body py-4">
        <div class="mb-2" id="password-confirmation-modal-text"></div>
        <div class="mb-3 input-group">
          <input
            libToggleUserPasswordVisibility
            type="password"
            class="form-control"
            id="password-confirmation-input" />
        </div>
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button
          id="password-confirmation-modal-deny"
          type="button"
          class="btn btn-outline-primary me-2"
          data-bs-dismiss="modal">
          Cancel
        </button>
        <button id="password-confirmation-modal-confirm" type="button" class="btn btn-primary px-4">Submit</button>
      </div>
    </div>
  </div>
</div>

<div id="string-confirmation-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog mt-2">
    <div class="modal-content shadow-lg">
      <div class="modal-body py-4">
        <div class="mb-2" id="string-confirmation-modal-text"></div>
        <div class="mb-2">
          Type<span id="string-confirmation-modal-confirmation-string" class="fw-bold mx-1"></span>to confirm:
        </div>
        <div class="mb-3 input-group">
          <input class="form-control" id="string-confirmation-input" />
        </div>
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button
          id="string-confirmation-modal-deny"
          type="button"
          class="btn btn-outline-primary me-2"
          data-bs-dismiss="modal">
          Cancel
        </button>
        <button id="string-confirmation-modal-confirm" type="button" class="btn btn-primary px-4">Confirm</button>
      </div>
    </div>
  </div>
</div>
