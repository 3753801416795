import {AuthDestroyerService, IDestroyable} from '@/lib/app/services/auth-destroyer.service'
import {Dictionary, forEach, keyBy} from 'lodash'

export class BaseCollectionStore<T extends {id}> implements IDestroyable {
  protected _items: T[]
  protected _itemsById: Dictionary<T>

  constructor(private _destroyer: AuthDestroyerService) {
    if (!_destroyer) {
      throw new Error(
        [
          'Unable to construct parent BaseCollectionStore for:',
          this.constructor.name,
          "Ensure you've created a constructor in your derived class which invokes super()",
        ].join(' ')
      )
    }

    _destroyer.registerHandler(this)
  }

  get items(): T[] {
    return this._items
  }

  get byId(): Dictionary<T> {
    return this._itemsById
  }

  setItems(items: T[]) {
    this._items = items
    this._itemsById = keyBy(this._items, 'id')
  }

  upsert(item: T) {
    if (!this._items) {
      this._items = [item]
      this._itemsById = {[item.id]: item}
      return
    }
    const t = this._items.find(t => t.id === item.id)
    if (t) {
      Object.assign(t, item)
    } else {
      this._items.push(item)
      this._itemsById[item.id] = item
    }
  }

  upsertMany(items: T[]) {
    for (const item of items) {
      this.upsert(item)
    }
  }

  remove(id: T['id']) {
    this._items = this._items.filter(t => t.id !== id)
    delete this._itemsById[id]
  }

  async destroy() {
    this._items = []
  }
}
