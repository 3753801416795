<div
  *ngFor="let alert of alerts; let i = index"
  class="alert alert-dismissible show shadow mt-2 p-4"
  [ngClass]="cssClass(alert)"
  role="alert">
  <span [innerHTML]="alert.message"></span>
  <button
    type="button"
    (click)="removeAlert(alert)"
    class="btn-close"
    data-bs-dismiss="alert"
    aria-label="Close"></button>
</div>
